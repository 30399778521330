import React from "react";
import Test1 from "./Test1";
import Testimonials from "../Testimonials/Testimonials";

import { headingData, subHeadingdata } from "../../buyAnAkiya/BuyAnAkiyaHook";
import HeadingContainer from "../../headingContainer/HeadingContainer";
import AkiyaForm from "../../akiyaForm/AkiyaForm";

function Acquisition() {
  return (
    <div>
      <Test1 />
      <Testimonials />
      <div className="h-[10vh] w-full"></div>

      <div className="w-full flex items-center justify-center">

        <div className="flex flex-col md:flex-row w-full max-w-[1080px] my-5 gap-12">
          <div className=" md:w-[50%] w-full p-2">
            <HeadingContainer data={headingData[1]} />
          </div>
          <div className=" md:w-[50%] w-full">
            <AkiyaForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acquisition;
