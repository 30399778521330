import React from 'react';
import { Link } from 'react-router-dom';

function Single_property({ property, hideKnowMore }) {
  return (
    <div className='bg-white flex flex-col justify-between shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px] w-full'>
      <div className='mx-6 r_xs:mx-0 py-[3rem]'>
        <img className='w-full h-[18rem] object-cover' src={property.images[0]} alt="" />
        <h1 className='text-[#5ab963] text-[24px] mt-8'>{property.propertyName}</h1>
        <p className='text-[#949995] text-[16px] my-5'>{property.smallAbout}</p>
      </div>
      {!hideKnowMore && (
        <div>
          <Link to={`/property/${property.serialNumber}`}>
            <button className='w-full flex justify-center items-center py-[1.3rem] bg-[#5ab963] text-white font-[800] text-[14px]'>Know More</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default Single_property;
