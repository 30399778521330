import React from "react";

function AllInOne(props) {
  return (
    <div className="mx-[4rem] r_xxs:max-w-[100vw] r_xs:max-w-[100vw] r_xs:mx-auto r_xxs:mx-auto">
      <div className="flex r_xxs:flex-col r_xs:flex-col">
        <div className="w-[85%]   r_xs:w-[100%] r_xxs:w-[100%]">
          <h1 className="text-[72px] leading-[78px] font-[800] text-[#333] r_sm:text-[60px] r_xs:text-[40px] r_xxs:text-[40px] r_xxs:text-center r_xs:text-center">{props.property.propertyName}</h1>
        </div>
        <div className="w-[15%] flex justify-center items-center r_xs:w-[100%] r_xxs:w-[100%] r_xs:mb-[40px] r_xxs:mb-[40px]">
          <button className="px-[30px] py-[15px] bg-[#5ab963] rounded text-white font-[800] text-[14px]">Book Now</button>
        </div>
      </div>
    </div>
  );
}

export default AllInOne;
