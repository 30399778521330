
import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
// import CustomButton from "../CustomButton/CustomButton";

const fields = [
  { type: "text", placeholder: "First name", name:"first_name" },
  { type: "text", placeholder: "Last Name", name:"last_name"  },
  { type: "email", placeholder: "Email", name:"mail"  },
  { type: "number", placeholder: "Age", cursor: "pointer", name:"age"  }, // Added cursor property for Age
  { type: "text", placeholder: "Nationality", name:"country"  }, // Removed dropdown property for Nationality
  { type: "text", placeholder: "Current University/Job", name:"current"  },
  { type: "text", placeholder: "Availability", name:"availability"  },
  { type: "text", placeholder: "About Me", name:"about"  },

];

const nationalityList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia (Czech Republic)",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini (fmr. 'Swaziland')",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia (formerly Macedonia)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];


const InternshipForm = () => {
  const form = useRef();
  const [showMessage, setShowMessage] = useState(false); // State to manage message box visibility
  const [nationality, setNationality] = useState(""); // State to hold the typed nationality
  const [showOptions, setShowOptions] = useState(false); // State to control visibility of options
  const nationalityRef = useRef(null); // Ref to the nationality input field

  // Function to handle changes in the nationality input
  const handleNationalityChange = (event) => {
    const value = event.target.value;
    setNationality(value);
    setShowOptions(true); // Show options when user starts typing
  };

  // Function to handle selection from the options
  const handleSelectNationality = (selectedNation) => {
    setNationality(selectedNation);
    setShowOptions(false); // Hide options after selection
  };

  const handleSelectOthers = () => {
    setNationality("Others");
    setShowOptions(false);
  };

  const handleClickOutside = (event) => {
    if (nationalityRef.current && !nationalityRef.current.contains(event.target)) {
      setShowOptions(false); // Hide options if clicked outside the nationality input field
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Add event listener on component mount

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Remove event listener on component unmount
    };
  }, []);

  const heading = "Apply Now!";


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_sf1j1kl', 'template_bnjvorp', form.current, {
        publicKey: 's0xkd41sUOcqpCOVk',
      })
      .then(() => {
        console.log('SUCCESS!');
        setShowMessage(true); // Show message box
        setTimeout(() => {
          setShowMessage(false); // Hide message box after 5 seconds
          window.location.href = '/'; // Redirect to home page
        }, 5000);
      })
      .catch((error) => {
        console.log('FAILED...', error.text);
      });
  };

  return (
<div className="text-center text-black">
  <h2 className="mb-2 font-bold mt-7" style={{ fontSize: '2em' }}>{heading}</h2>

  <div className="relative max-w-[100%] mx-[30px]">
    <form id="internform"  ref={form} onSubmit={sendEmail} className="p-4 bg-white">
      <div className="flex flex-wrap">
        {fields.map((field, index) => (
          <div key={index} className="w-full sm:w-1/2 px-2 mb-4">
            {field.placeholder === "Nationality" ? (
              <div className="relative" ref={nationalityRef}>
                <input
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={nationality}
                  onChange={handleNationalityChange}
                  className="w-full h-12 px-4 border border-gray-300 border-l-0 border-r-0 border-t-0 focus:outline-none"
                />
                {showOptions && (
                  <div className="absolute mt-1 w-full bg-white border border-gray-300 border-l-0 border-r-0 border-t-0 shadow-md">
                    {nationalityList
                      .filter((nation) =>
                        nation.toLowerCase().includes(nationality.toLowerCase())
                      )
                      .map((nation, index) => (
                        <div key={index} onClick={() => handleSelectNationality(nation)} className="cursor-pointer hover:bg-gray-200 py-2 px-4">
                          {nation}
                        </div>
                      ))}
                    {!nationalityList.includes(nationality) && (
                      <div onClick={handleSelectOthers} className="cursor-pointer hover:bg-gray-200 py-2 px-4">
                        Others
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                className={`w-full h-12 px-4 border border-gray-300 border-l-0 border-r-0 border-t-0 focus:outline-none ${field.cursor ? "cursor-pointer" : ""}`}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 mb-6 font-[500]">
          Submit
        </button>
      </div>
    </form>
   {/* Message box */}
       {showMessage && (
         <div className="absolute bottom-4 left-4 bg-white py-2 px-4 rounded-lg border border-gray-300">
           <p>Your message is received. You will be contacted soon.</p>
         </div>
       )}
  </div>
</div>
  );
};

export default InternshipForm;







// import React, { useRef, useState } from 'react';
// import emailjs from '@emailjs/browser';
// import CustomButton from "../CustomButton/CustomButton";

// const fields = [
//   { type: "text", placeholder: "Your name", name:"name"},
//   { type: "email", placeholder: "Your email", name:"mail" },
//   { type: "text", placeholder: "Your Country", name:"country" },
//   { type: "text", placeholder: "Tell us all about it",name:"query" },
// ];

// const AkiyaForm = () => {
//   const form = useRef();
//   const [showMessage, setShowMessage] = useState(false); // State to manage message box visibility

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm('service_sf1j1kl', 'template_4hxx0oe', form.current, {
//         publicKey: 's0xkd41sUOcqpCOVk',
//       })
//       .then(() => {
//         console.log('SUCCESS!');
//         setShowMessage(true); // Show message box
//         setTimeout(() => {
//           setShowMessage(false); // Hide message box after 5 seconds
//           window.location.href = '/'; // Redirect to home page
//         }, 5000);
//       })
//       .catch((error) => {
//         console.log('FAILED...', error.text);
//       });
//   };

//   return (
//     <div className="relative">
//       <form ref={form} onSubmit={sendEmail} className="w-[100%] flex flex-col gap-4">
//         {fields.map((field, index) => (
//           <input
//             key={index}
//             type={field.type}
//             placeholder={field.placeholder}
//             name={field.name}
//             className="w-full h-16 border-b-[1px] text-sm placeholder-sm:text-sm outline-none focus:outline-none"
//           />
//         ))}
        
//         <div>
//           <CustomButton text={"Submit "} />
//         </div>
//       </form>
      
//       {/* Message box */}
//       {showMessage && (
//         <div className="absolute bottom-4 left-4 bg-white py-2 px-4 rounded-lg border border-gray-300">
//           <p>Your message is received. You will be contacted soon.</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AkiyaForm;
