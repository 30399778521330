import React, { useState } from "react";

const SliderImagesContainer = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="w-full lg:h-[45rem] m:h-[30rem] sm:h-[20rem] h-[15rem] relative r_xs:w-[93%] r_xs:mx-3 r_xxs:w-[93%] r_xxs:mx-3">
      {images.map((image, index) => (
        <img
          loading="lazy"
          key={index}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
          src={image}
          alt={`Image ${index}`}
        />
      ))}
      <button
        onClick={showPreviousImage}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-2xl p-2"
        style={{ cursor: "pointer" }}
      >
        &#10094; {/* Left arrow symbol */}
      </button>
      <button
        onClick={showNextImage}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-white text-2xl p-2"
        style={{ cursor: "pointer" }}
      >
        &#10095; {/* Right arrow symbol */}
      </button>
      <span className="absolute bottom-5 text-white flex gap-2 right-[48%] text-3xl">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-[8px] h-[8px] rounded-full ${
              currentIndex === index ? "bg-white" : "bg-slate-400"
            }`}
          ></button>
        ))}
      </span>
    </div>
  );
};

export default SliderImagesContainer;
