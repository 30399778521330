import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import CardSlider from "../../cardSlider/CardSlider";
import TeamList from "./TeamList";
import data from "./InternData";
import CardSlider1 from "./CardSlider1";
function SingleInternCard({ name, post, about, linkToLinkedin, image }) {
  return (
    <div className="w-full sm:w-[50%] lg:w-[33%] xl:w-[25%] px-2 mb-8">
      <div className="flex flex-col items-center justify-center p-4 rounded-lg shadow-xl">
        <img src={image} alt={name} className="w-full mb-4 rounded" />
        <h1 className="text-green-500 text-2xl mb-2">{name}</h1>
        <h2 className="text-[1rem] text-gray-400 mb-2 leading-[1.5rem]">
          {post}
        </h2>
        <p className="text-[0.875rem] text-gray-400 mb-4 leading-[1.25rem]">
          {about}
        </p>
        <div className="flex justify-center">
          <Link
            to={linkToLinkedin}
            target="_blank"
            className="text-[#222] text-xl"
          >
            {" "}
            <FaLinkedin className="text-3xl text-[#5ab963] hover:text-[#0077b5] transition duration-300" />
          </Link>
        </div>
        <div className="mt-4">
          <Link to="../../blogs">
            <button className="bg-[#5ab963] text-white text-sm font-semibold px-4 py-2 rounded">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function InternCard() {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <h1 className="w-full max-w-[1008px] text-[#333] text-2xl font-semibold mb-8 text-center">
        Hear from our interns past and present
      </h1>
<div className="w-[100vw] flex justify-center">
<div className="flex flex-wrap justify-around mt-8 max-w-[1440px] w-[100%]">
          {/* {data.map((founder) => (
          <TeamList {...founder} key={founder.name} />
        ))} */}
<CardSlider
            data={data}
            Card={TeamList}
            endValues={{
              xs: 1,
              md: 2,
              lg: 3,
            }}
          />
          {/* <CardSlider1
            data={data}
            Card={TeamList}
            endValues={{
              xs: 1,
              md: 2,
              lg: 3,
            }}
          /> */}
        </div>
</div>


    </div>
  );
}

export default InternCard;

{
  /* <CardSlider
data={latestBlogData}
Card={BlogCard}
endValues={{
  xs: 1,
  md: 2,
  lg: 3,
}}
/> */
}

// Working with Akiya2.0 for this internship has been amazing, as a startup I was able to be exposed to so many new things and I was able to grow immensely in my role as a marketing intern. With the flexibility and community-based nature of the organization, I had such an important experience for my writing, marketing, and fundraising skills surrounded by wonderful people.
// Working as a Software Engineer Intern at Akiya2 has been an incredible journey. I've had the opportunity to dive into a variety of exciting projects that have significantly broadened my technical skills. The collaborative environment at Akiya2 has been truly inspiring, fostering creativity and innovation. I've gained hands-on experience with cutting-edge technologies, and the mentorship I've received has been invaluable in honing my problem-solving abilities. Overall, it has been an amazing experience that has not only enhanced my technical acumen but also shaped me as a professional.
