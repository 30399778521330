import React from "react";
import HeadingContainer from "../../Components/headingContainer/HeadingContainer";
import SubHeadingContainer from "../../Components/subHeadingContainer/SubHeadingContainer";
import { headingData, subHeadingdata } from "./GrowYourAkiyaHook";
import AkiyaForm from "../akiyaForm/AkiyaForm";
import HeadingForAll from "../HeadingForAll/HeadingForAll";

const GrowYourAkiya = () => {
  const firstHeading = "Investor services";
  const secondHeading = "An investment in Japan's rich heritage";
  return (
    <>
            <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />

      <div className="flex justify-center my-[2rem] ">
        <div className="max-w-[1440px] lg:mx-[3rem]  md:mx-[2rem] mx-[1rem]  ">
          <HeadingContainer data={headingData[0]} />
          <div className=" flex flex-col gap-8 mt-16">
            {subHeadingdata.map((item, index) => {
              return <SubHeadingContainer data={subHeadingdata[index]} />;
            })}
          </div>
          <div className="flex flex-col md:flex-row   w-full gap-8 ">
            <div className=" md:w-[50%] w-full p-2">
              <HeadingContainer data={headingData[1]} />
            </div>
            <div className=" md:w-[50%] w-full">
              <AkiyaForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowYourAkiya;
