import React from "react";
import HeadingContainer from "../headingContainer/HeadingContainer";
import Properties_listing from "../homePage/HomePageComponents/Properties_listing";
const CommingSoon = ({ prefectureName }) => {
  const data = {
    heading: `${prefectureName} Akiya Listings Coming Soon`,
    info: `Exciting news! We're preparing to unveil a collection of Akiya listings in ${prefectureName}. Stay tuned for a curated selection of unique properties that could be your next home. Discover the charm of ${prefectureName} and explore the possibilities that await you.`,
  };
  return (
    <div>
      <div className={`mt-10`}>
        <h1 className="text-3xl">{data.heading}</h1>
          <p className="text-[--dark-grey] font-[--libre]" >{data.info}</p>
      </div>
      <div>
      <h1 className="text-3xl">Our Properties:</h1>
      <Properties_listing />
      </div>
    </div>
  );
};

export default CommingSoon;
