import React from 'react';
import FamousPlaces from './FamousPlaces';
import { notoFamousPlaces } from './our-project-data'; // Corrected import statement

function ThirdSOV() {
  return (
<div className='flex flex-wrap mt-[60%] r_lg:mt-[50rem] r_md:mt-[35rem] r_sm:mt-[40rem] r_xs:mt-[45rem]'>
  {notoFamousPlaces.map((singlePlace) => (
    <div key={singlePlace.id} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4 flex justify-center">
      <FamousPlaces data={singlePlace} />
    </div>
  ))}
</div>

  );
}

export default ThirdSOV;
