import React, { useState, useEffect, useRef } from "react";
import useWindowDimentions from "../useWindowDimensions";
function AkiyaGallery({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const thumbnailsRef = useRef(null);
  const { width } = useWindowDimentions();
  useEffect(() => {
    scrollToThumbnail(currentImageIndex);
  }, [currentImageIndex]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const changeImage = (index) => {
    setCurrentImageIndex(index);
  };

  const scrollToThumbnail = (index) => {
    if (thumbnailsRef.current) {
      const thumbnailElement = thumbnailsRef.current.childNodes[index];
      if (thumbnailElement) {
        const container = thumbnailsRef.current;
        const top = thumbnailElement.offsetTop;
        const bottom =
          thumbnailElement.offsetTop + thumbnailElement.offsetHeight;
        const viewTop = container.scrollTop;
        const viewBottom = container.scrollTop + container.clientHeight;

        if (top < viewTop || bottom > viewBottom) {
          container.scrollTo({
            top: thumbnailElement.offsetTop - container.offsetTop,
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <div className=" gallery mt-[2rem] sm:h-[24rem] h-[20rem]    md:h-[30rem] lg:h-[36rem] md: w-full   flex   mb-20">
      <div className="main-image relative h-full lg:w-[80%] w-full  rounded-lg  ">
        <button
          className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]"
          onClick={prevImage}
        >
          &#10094;
        </button>
        <img
          className="h-full  w-full rounded-lg max-w-full object-fill"
          src={images[currentImageIndex]}
          alt="Main"
        />
        <button
          className="next-btn absolute top-1/2 transform -translate-y-1/2 right-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]"
          onClick={nextImage}
        >
          &#10095;
        </button>
      </div>
      {width >= 1024 && (
        <div className="w-[20%] h-full  ">
          <div
            className="w-[100%] flex  lg:flex-col flex-row lg:h-[36rem] md:h-[12rem] overflow-auto "
            ref={thumbnailsRef}
          >
            {images.map((image, index) => (
              <div key={index} className="cursor-pointer">
                <img
                  src={image}
                  alt={`Thumbnail ${index}`}
                  onClick={() => changeImage(index)}
                  className={`w-[200px] h-[175px] thumbnail ${index === currentImageIndex ? "active" : "not-so-active"}`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AkiyaGallery;

//md
