import React, { useState } from "react";

const SliderImagesContainerForInvestor = ({ images, headers, subHeaders, paragraphs, makers }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div
        className="absolute top-1/2 -right-[2.5rem] transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
        onClick={onClick}
      >
        <button className="next-btn absolute top-1/2 transform -translate-y-1/2 right-1 text-white bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]">
          &#10095;
        </button>
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div
        className="absolute top-1/2 -left-[3rem] transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
        onClick={onClick}
      >
        <button className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-white bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]">
          &#10094;
        </button>
      </div>
    );
  };

  const topLeft = [0]
  const bottomRight = [1, 2]

  const positionClass = topLeft.includes(currentIndex) 
  ? 'top-0 left-0' 
  : (bottomRight.includes(currentIndex) 
      ? 'bottom-0 right-0' 
      : ''); // default case if the index is not in any array

  return (
    <div className="relative w-11/12 md:w-11/12 lg:w-12/12 mx-auto">
      <div className="relative w-full h-[15rem] sm:h-[20rem] md:h-[30rem] lg:h-[45rem]">
        <div className="relative w-full h-full">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              loading="lazy"
              className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              }`}
            />
          ))}
          <PrevArrow onClick={showPreviousImage} />
          <NextArrow onClick={showNextImage} />
          <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex gap-2">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full ${
                  currentIndex === index ? "bg-white" : "bg-slate-400"
                }`}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
          </div>
          <div
            className={`classical pb-[2rem] pt-[1rem] w-[38%] h-[fit-content] bg-white absolute ${positionClass} shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px]`}
          >
            <h1 className=" text-black text-[32px] font-[800] leading-[36px] mx-[22px]">{headers[currentIndex]}</h1>
            <h1 className="mb-[1rem] text-[#5ab963] text-[24px] font-[300] leading-[36px] mx-[22px] mt-0">
              {subHeaders[currentIndex]}
            </h1>
            <p className="text-justify text-[#949995] text-[16px] font-[300] leading-[24px] mx-[22px] mt-0">
              {paragraphs[currentIndex]}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full text-[#949995] mt-4 flex text-lg ml-3">
        {makers[currentIndex]}
      </div>
    </div>
  );
};

export default SliderImagesContainerForInvestor;
