import React from "react";
import TimeOutImages from "../sliderImagesContainer/SliderImagesContainer";

export default function WithLeftBox({ data }) {
  const { boxHeader, boxHeader2, boxPara, images } = data;

  return (
    <div className="w-[100%] lg:h-[50rem] h-[50rem] relative extra_spacing">
      <div className="w-[100%] h-[92%] absolute bottom-0 classical2">
        <TimeOutImages images={images} />
      </div>
      <div className="classical pb-[4rem] pt-[1rem] w-[35%] h-[fit-content] bg-white absolute top-0 left-0 shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px]">
        <h1 className="dark-header mb-0">{boxHeader}</h1>
        <h1 className="mb-[2rem] text-[#5ab963] text-[24px] font-[300] leading-[36px] mx-[30px] mt-0">
          {boxHeader2}
        </h1>
        <p className="text-justify text-[#949995] text-[16px] font-[300] leading-[24px] mx-[30px] mt-0">
          {boxPara}
        </p>
      </div>
    </div>
  );
}
