import React from "react";
import GreenBoxes from "./GreenBoxes";
import { Link } from "react-router-dom";
const Purchase = () => {
  const dark_heading = "Purchase Services";
  const dark_heading_2 = "The most commonly opted for Purchase Services:";
  const buttonText =
    "To help you begin, Akiya2.0 has compiled a completely free akiya database, translated from our Japanese partners and resources and catalogued for your convenience. ";
  const light_heading = (
    <>
The first step on your akiya journey is to search for, locate and purchase the correct kominka. In many ways, this can seem like the most difficult barrier to overcome. Let Akiya2.0 sift through and take care of all the legal and regulatory details, that you can focus on choosing the right akiya for your purposes. 
      <br />
      <br />
      Some of our services at this stage include :
    </>
  );

  const light_heading_2 = (
    <>
      Search Assistance
      <br />
      Market Analysis (Optional)
      <br />
      Site Inspection
      <br />
      Regulatory Advisory
      <br />
      Buyer Side Negotiations + Contract Liaison
      <br />
      Other Language Services
    </>
  );

  const boxData = [
    {
      id: "firstBox",
      heading: "Akiya Liaison Subscription",
      content: (
        <>
          Akiya2.0 will help you step-by-step to search for your perfect
          property across prefectures and ensure that you get amazing options to
          choose from, so you know that you made the correct decision
          <br />
          <br />
          50,000yen/ month for 1x 3 month subscription of Akiya Liasion
        </>
      ),
    },
    {
      id: "secondBox",
      heading: "Remote Property Recee",
      content: (
        <>
          For remote buyers, we represent you to recee the prospective property,
          providing you with relevant architectural and business advice to help
          you make a sound decision.
          <br />
          <br />
          Importantly we provide live-stream/ liaison translation so you can be
          directly involved from the comfort of your own sofa.
          <br />
          <br />
          100,000yen (+ Transport fees) for 1 x Remote Property Recee (Discounts
          available for multiple property per visit)
        </>
      ),
    },
    {
      id: "MainBox",
      smallHeader: "Basic Akiya2.0 purchase service",
      bigHeader: "Starting from 150,000- yen only for 1 month",
    },
  ];

  return (
    <div className="py-3 h-full">
      <h1 className="dark-header text-2xl sm:text-3xl md:text-3.5xl">
        {dark_heading}
      </h1>
      <p className="light-header text-lg sm:text-xl md:text-2xl md:leading-9">
        {light_heading}
      </p>
      <p className="mx-4 sm:mx-6 md:mx-8 lg:mx-12 font-bold mb-8 text-base sm:text-lg md:text-xl md:leading-8">
        {light_heading_2}
      </p>
      <p className="light-header text-lg sm:text-xl md:text-2xl md:leading-9">
        {buttonText}
      </p>
      <div className="flex justify-center items-center mb-10">
        <Link to="/akiya-search">
          <button className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800]">
            Akiya Search
          </button>
        </Link>
      </div>
      <h1 className="dark-header text-2xl sm:text-3xl md:text-4xl mt-[5rem]">
        {dark_heading_2}
      </h1>

      <GreenBoxes data={boxData} color={["rgb(67,146,89)", "rgb(58,127,77)"]} />
    </div>
  );
};

export default Purchase;

// bg-[rgb(67,146,89)]
// bg-[rgb(58,127,77)]
