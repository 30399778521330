import React, { useState } from "react";
import "./individualProperty.css";
function Gallery({ images, video }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const changeImage = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="mx-[4rem] r_xxs:mx-0 r_xs:mx-0 gallery mt-[2rem] flex flex-col justify-center items-center">
      <div className="main-image relative r_sm:max-w-[100vw] r_md:max-w-[100vw] r_xxs:max-w-[100vw] r_xs:max-w-[100vw]">
        <button
          className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]"
          onClick={prevImage}
        >
          &#10094;
        </button>
        <div className="">
          <img
            loading="lazy"
            className="not-so-active h-[95%] w-[100%] max-h-[90vh]"
            src={images[currentImageIndex]}
            alt="Main"
          />
        </div>
        <button
          className="next-btn absolute top-1/2 transform -translate-y-1/2 right-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]"
          onClick={nextImage}
        >
          &#10095;
        </button>
      </div>
      <div className="flex flex-row h-[fit-content] my-[1rem] r_xxs:flex-col r_xs:flex-col  r_xxs:max-w-[100vw] r_xs:max-w-[100vw]">
        <div className="r_xxs:w-[100%] r_xs:w-[100%] w-[50%] pr-[1rem] r_xxs:pr-0 r_xs:pr-0 flex justify-center items-center">
        {/* <div className="w-[100%]"> */}

          {/* <iframe
            loading="lazy"
            className="not-so-active w-[100%]"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ZzB1wF_cuJ4?si=sCqMCr5RzuAngFZp"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}

<div className="w-[100%] h-[100%]" dangerouslySetInnerHTML={{ __html: video }} />
{/* {video} */}
        </div>
        <div className="thumbnail flex flex-wrap w-[50%] r_xxs:hidden r_xs:hidden">
          {images.slice(0, 9).map((image, index) => (
            <div className="r_xxs:w-1/1 r_xs:w-1/2 r_sm:w-1/3 r_md:w-1/3 r_lg:w-1/3 r_xl:w-1/3">
              <img
                loading="lazy"
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                onClick={() => changeImage(index)}
                className={`mb-[2px] mr-[2px] w-[100%] h-[100%] max-h-[9rem] thumbnail ${index === currentImageIndex ? "active" : "not-so-active"}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Gallery;
