import React from "react";
import "../CustomButton/CustomButton.css";
const CustomButton = ({ text, rounded = true,handleButtonClick }) => {
  return (
<button
  onClick={handleButtonClick}
  className={`custom-button bg-[var(--medium-sea-green)] hover:bg-[var(--sea-green)] inline-block ${
    rounded ? "md:rounded-md" : ""
  } text-white font-semibold py-4 px-7 w-full md:w-auto`}
>
  <span>{text}</span>
</button>
  );
};

export default CustomButton;

