import React from "react";
import { useNavigate } from "react-router-dom";

//500
const BlogCards = ({ item }) => {
  console.log(item)
  const { title, imageUrls, date, content, index, author } = item;
  const navigate = useNavigate();

  return (
    <div
      className="flex shadow-lg lg:h-[36rem] md:h-[33rem] lg:w-[100%] cursor-pointer "
      onClick={() => navigate(`/blogs/${index}`)}
    >
      <div className="flex flex-col ">
        <div className=" h-[18rem]">
          {/* Set a fixed width and height for the image */}
          <img
            loading="lazy"
            src={imageUrls}
            alt="Event"
            className="w-[100%] h-full object-cover"
          />
        </div>

        <div className="mx-3 px-[0.8rem]">
          <div className="flex justify-between mt-[2rem]">
            <p className="text-[--medium-sea-green]">{author}</p>
            <p className="text-[--medium-sea-green]">{date}</p>
          </div>
          <h1 className="mt-0 text-center leading-[36px] text-[24px] font-[800]">
            {title}
          </h1>

          <p className="text-[#949995] text-[16px] font-[300] leading-[24px] pb-5">
            {content}....
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
//md:w-[50%] sm:w-full lg:w-[33%] md:h-[27rem] sm:h-[25rem] lg:h-[30rem] xs:h-[20rem] m-2 flex shadow-2xl flex-col justify-between gap-4
