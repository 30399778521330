import React from "react";
import { latestBlogData } from "../community/useCommunityHook";
import BlogCards from "./BlogCards";
import { Link } from "react-router-dom";
import useAllBlogsHook from "./useAllBlogsHook";

function AllBlogs() {
  const { blogs, setBlogs } = useAllBlogsHook();
  console.log(blogs);
  const firstHeading = "Blogging the Akiya";
  const secondIntro = "Everyone knows there are over 8 million akiya";
  const aboutSecondIntro = "Here's the fuss-free way to actually own one.";
  const secondHeading = (
    <>
      Get updates on your Akiya
      <br />
      anytime, anywhere
    </>
  );

  return (
    <>
      <div className="bg-[#5ab963] flex justify-center">
        <div className="ml-[3rem] text-[#fff] pb-[1rem] w-[100%] max-w-[1440px]">
        <h1 className="m-0 md:text-[24px] text-[15px] font-[800] pt-[0.5rem]">
            {firstHeading}
          </h1>
          <h1 className="md:text-[72px] text-[32px] font-[800] md:leading-[78px] leading-[35px] pt-[0.5rem] pb-[10.5rem]">
            {secondHeading}
          </h1>
        </div>
      </div>

      <div className="w-[100vw] flex justify-center items-center">
        <div className="max-w-[1440px]">
        <div className="grid px-[1rem] translate-y-[-8rem] mx-[1.5rem] grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {blogs &&
  [...blogs].reverse().map((x, index) => <BlogCards key={index} item={x} />)}

</div>


          <div className="flex my-[5rem]">
            <div className="w-[50%]">
              <h1 className="dark-header my-0">{secondIntro}</h1>
              <h1 className="light-header my-0">{aboutSecondIntro}</h1>
            </div>
            <div className="flex justify-center items-center w-[50%]">
              <Link to="/services/how-it-works">
                <button className="py-[15px] px-[30px] bg-[#5ab963] rounded text-white text-[16px] font-[800]">
                  Our Services
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllBlogs;
