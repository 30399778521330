import { useEffect, useState } from "react";
import TimeOutImages from "../sliderImagesContainer/SliderImagesContainer";

// Define a function to import all images from a given context
function importAllImages(context) {
  const images = [];
  context.keys().forEach((key) => images.push(context(key)));
  return images;
}

// Import images from the "Events" directory
const eventImagesContext = require.context(
  "../../Components/images/Community/Events",
  false,
  /\.(webp|png|jpe?g|svg)$/
);
const eventImages = importAllImages(eventImagesContext);

// Import images from the "TimeOutImages" directory
const sliderImagesContext = require.context(
  "../../Components/images/Community/SliderImages",
  false,
  /\.(webp|png|jpe?g|svg)$/
);
const sliderImages = importAllImages(sliderImagesContext);

// Export the imported images
export { sliderImages };

export const headingData = [
  {
    heading: "Upcoming local events",
    info: "Coming to visit your akiya? Here are some events happening around our Travel Club areas",
  },
  {
    heading: "Latest blog posts",
    info: "Discover a spectrum of insights in our team's and intern's blogs and follow along the restoration process of your favorite Akiya.",
  },
  {
    heading: "Calling all students, we are looking for interns!",
    info: "‍Embark on a transformative journey with Akiya2.0 internships. Gain hands-on experience, expand your skills, and immerse yourself in Japanese culture.",
  },
];
export const eventData = [
  {
    title: "Amamehagi Festival",
    date: "February 03, 2024",
    place: "Hosu District, Noto",
    content:
      "Young children dress up as demons and shout 'Amame!' to warn farmers of the dangers of laziness before the spring cultivation season.",
    imageUrls: [eventImages[0]],
    url: "https://www.city.suzu.lg.jp/site/laporte-suzu/10470.html",
  },
  {
    title: "Concerto per Tutti 5",
    date: "December 23, 2023",
    place: "Laporte Suzu Large Hall",
    content: "",
    imageUrls: [eventImages[1]],
    url: "https://www.city.suzu.lg.jp/site/laporte-suzu/10520.html",
  },
  {
    title: "Recital : Peter and the Wolf",
    date: "December 17, 2023",
    place: "Laporte Suzu Large Hall",
    content: "",
    imageUrls: [eventImages[2]],
    url: "https://www.city.suzu.lg.jp/site/laporte-suzu/10470.html",
  },
];
// make a context and there call all blogs and sort in basis of latest 3 logic
// to implement  to be used in useCommunityHook and will be used in individual listings

//note these imageurls are temp i will take data from the api itself
export const latestBlogData = [
  {
    imageUrls:
      "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/658418d4b5fa02f7c74c094b_nick-wood-k7RBbtooQV8-unsplash-p-2000.jpg",
    date: "December 21, 2023",
    title: "Mission to revive traditional Akiyas by Akiya2.0",
    content:
      "Akiya2.0 aims to revitalize traditional akiyas through innovative strategies, preserving cultural heritage, and fostering sustainable community development in Japan.",
  },
  {
    imageUrls:
      "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/6584187cee1e1fd8dd898776_moiz-k-malik-68aXi4RVhMQ-unsplash%20(1)-p-2000.jpg",
    date: "December 21, 2023",
    title: "What does an “Akiya” even mean",
    content:
      "Akiya refers to abandoned or vacant homes in Japan, often in rural areas. They are a focal point for revitalization initiatives addressing population decline and urbanization.",
  },
  {
    imageUrls:
      "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/657b10cb49af2a8e2e005883_aditya-anjagi-KZSDCocsOEE-unsplash-p-2000.jpg",
    date: "December 14, 2023",
    title: "Traditional Japanese Discipline: The Essence",
    content:
      "Traditional Japanese Discipline: The Essence Timeless Threads: How Japan Weaves its Tradition Through Ages",
  },
  {
    imageUrls:
      "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/657b10cb49af2a8e2e005883_aditya-anjagi-KZSDCocsOEE-unsplash-p-2000.jpg",
    date: "December 14, 2023",
    title: "Traditional Japanese Discipline: The Essence",
    content:
      "Traditional Japanese Discipline: The Essence Timeless Threads: How Japan Weaves its Tradition Through Ages",
  },
  {
    imageUrls:
      "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/657b10cb49af2a8e2e005883_aditya-anjagi-KZSDCocsOEE-unsplash-p-2000.jpg",
    date: "December 14, 2023",
    title: "Traditional Japanese Discipline: The Essence",
    content:
      "Traditional Japanese Discipline: The Essence Timeless Threads: How Japan Weaves its Tradition Through Ages",
  },
];
export const headingForAllData = {
  subheading: "Follow along with us",
  heading: "Its more than just a house - its a community",
};

// useEffect(() => {
//  const sortedByDate= headingData.sort((a,b)=>a.date-b.date);
//  setEventData(sortedByDate);

// }, [])
