import React from "react";
import AkiyaForm from "../akiyaForm/AkiyaForm";
function ThirdSOV() {
  const contact = [
    ["Email:", "contact@akiya2.com"],
    ["Tel:", "050-1791-0477"],
    [
      "Address:",
      <>
        <b>Akiya2.0 K.K.</b>
        <br />
        Seika Bldg. 5F
        <br />
        3-5-17 Kojimachi Chiyoda Ward
        <br />
        Tokyo 102-0083
      </>,
    ],
  ];

  return (
    <div> 
    <div id="form"></div>
      <h1 className="dark-header mb-0">Have a question for us?</h1>
      <h1 className="light-header mt-0">

        Go ahead and send us any enquires.
        <br />
        Our friendly staff will get back to you as soon as we are able.
      </h1>

      <div className="flex">
        <div className="w-[45%] flex items-center">
          <div className="flex-col-reverse">
            {contact.map(([label, value], index) => (
              <div key={index} id="contact" className="flex ml-[30px] mb-[2rem]">
                <p className="text-[#999] text-[14px] font-[400] w-[40%]">{label}</p>
                <p className="text-[14px] font-[400] w-[60%]">{value}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="w-[55%] p-[3rem]">
          <AkiyaForm />
        </div>
      </div>
    </div>
  );
}

export default ThirdSOV;
