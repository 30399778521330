import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import HouseBuy from "../Svgs/HouseBuy";
// import HouseRent from "../Svgs/HouseRent";
import gallery from "../Svgs/gallery.svg";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";
import Heart from "../Svgs/Heart";
import Trash from "../Svgs/Trash";

const AkiyaCard = ({ item, isFavorite = false, onDelete }) => {
  const {
    Images,
    Address,
    Area_of_House,
    Area_of_Land,
    Room_Type,
    Year_of_Construction,
    Price,
    property_id,
    Empty_or_Not,
  } = item;
  console.log(item);
  // const newPrice = Price.replace(/[^\d]/g, '');

  const [newPrice, setNewPrice] = useState(0);
  const [isValidImage, setIsValidImage] = useState(true);
  const navigate = useNavigate();
  const [formatedAreaOfLand, setFormatedAreaOfLand] = useState();
  const [formatedAreaOfHouse, setFormatedAreaOfHouse] = useState();
  const [isFavoritePresent, setIsFavoritePresent] = useState(false);
  const user = useUser();
  const UpdateUser = useUpdateUser();
  const { showModal, setShowModal } = useContext(UserContext);

  useEffect(() => {
    if (Price) {
      // Remove non-numeric characters
      const cleanedPrice = Price.replace(/[^\d]/g, "");
      // Convert to a number and format with commas

      // Set the formatted price
      setNewPrice(cleanedPrice);
    }
    
  }, []);
  useEffect(() => {
    //pending
    if (user && user.favorite.length) {
      const isPresent = user.favorite.find((f) => f === property_id);
      setIsFavoritePresent(isPresent);
    }

    if (Area_of_Land) {
      let land = Area_of_Land.replace("m", "").replace(" ", "");
      const isLastCharDigit = !isNaN(land.slice(-1));

      if (isLastCharDigit) {
        land += "m²";
      }
      setFormatedAreaOfLand(land);
    }

    if (Area_of_House) {
      let house = Area_of_House.replace("m", "").replace(" ", "");
      const isLastCharDigitHouse = !isNaN(house.slice(-1));

      if (isLastCharDigitHouse) {
        house += "m²";
      }

      setFormatedAreaOfHouse(house);
    }
  }, [Area_of_Land, Area_of_House]);
  const handleImageLoad = () => {
    setIsValidImage(true);
  };

  // Function to handle image error
  const handleImageError = () => {
    setIsValidImage(false);
  };
  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    if (!user) {
      setShowModal(true);
    } else if (isFavoritePresent) {
      setIsFavoritePresent(false);

      const favoriteData = user.favorite.filter((f) => f !== property_id);
      UpdateUser({ ...user, favorite: favoriteData });
    } else {
      setIsFavoritePresent(true);
      const favoriteData = [...user.favorite, property_id];
      UpdateUser({ ...user, favorite: favoriteData });
    }
  };
  // Remove last three characters from the price
  let priceWithoutLastThreeDigits;
  let formattedPrice;
  if (Price) {
    priceWithoutLastThreeDigits = Price.toString().slice(0, -3);
    formattedPrice = priceWithoutLastThreeDigits.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  }

  // Format the price with commas as thousand separators

  const japaneseYenSymbol = "\u00A5";

  return (
    <div
      className=" shadow-xl h-[32rem] w-full cursor-pointer relative group overflow-hidden transition-transform duration-300 transform hover:scale-105 "
      onClick={() => navigate(`/individual-akiya/${property_id}`)}
    >
      <div className="h-[40%] w-full relative">
        {/* lg:w-[19%] md:w-[32%] sm:w-[100%] h-[30rem] */}

        {!isFavorite && (
          <div className="absolute right-0 flex   w-[4rem] justify-end   h-[3rem] opacity-100 ">
            <button
              onClick={handleToggleFavorite}
              className={`${isFavoritePresent ? " text-[#5AB963]  " : "text-white "} px-2 m-1  stroke-[#5AB963] `}
            >
              <div className="w-10 h-10 ">
                <Heart />
              </div>
            </button>
          </div>
        )}

        <div className="absolute bottom-0   bg-black/60 w-full h-[3rem] text-white flex justify-center items-center ">
          {/* {Price &&   `${japaneseYenSymbol} ${newPrice}`} */}
          {Price && newPrice > 10000
          
            ? `${japaneseYenSymbol} ${newPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "Consult with administrator"}

          {/* {console.log(Price)} */}
        </div>
        {Images && Images[0] ? (
          <img
            loading="lazy"
            className="h-full w-full"
            src={Images[0]}
            alt="photo"
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        ) : (
          <img
            loading="lazy"
            className="h-full w-full"
            src={gallery}
            alt="photo"
          />
        )}
      </div>
      <div className="my-5 mx-2 flex flex-col gap-6 ">
        <div>
          <p className="mb-2">Address:</p>
          {Address && (
            <p className="text-[#333333] text-sm">{Address.slice(0, 90)}</p>
          )}
        </div>
        <div>
          <p className="mb-2">Floor Area:</p>
          <p className="text-[#333333] text-sm">{formatedAreaOfLand}</p>
        </div>
        <div>
          <p className="mb-2">House Area:</p>
          <p className="text-[#333333] text-sm">{formatedAreaOfHouse}</p>
        </div>
        {isFavorite && (
          <button
            className="bg-red-100 rounded-xl absolute bottom-2 right-[40%] "
            onClick={(event) => {
              event.stopPropagation();
              onDelete(property_id);
            }}
          >
            {/* put the delete icons here */}

            <Trash />
          </button>
        )}
      </div>
    </div>
  );
};

export default AkiyaCard;

{
  /* <div className="absolute bottom-1 right-2 text-[--sea-green]"> */
}

// </div>
