import React from 'react';
import "./Middle.css";

function Middle() {
    const heading = "Expand your perspectives with Akiya2.O";
    const paragraph = (
        <>
            Are you a budding photographer, content creator, architect or Japanophile? Uncover the beauty of Japan during a 1-2 month long adventure with our team. Blend your preferred destinations with engaging cultural
            experiences we offer. With our skilled guidance and continual assistance, immerse yourself in the unique charm of Japan like never before.
        </>
    );

    return (
        <div className='text-justify text-[24px] leading-[36px] my-[3rem] mr-[30px]'>
            <div>
                <h3 className='dark-header mb-3'>{heading}</h3>
            </div>
            <div>
                <h5 className='light-header'>{paragraph}</h5>
            </div>
        </div>
    );
}

export default Middle;