import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Loader from "../Loader/Loader";
import AkiyaAkiya from "../images/prefecture/AkiyaAkiya.png";

const MapIComponent = ({ apiKey, coordinates }) => {
  const [markers, setMarkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadCoordinates = () => {
      if (
        !coordinates ||
        !Array.isArray(coordinates) ||
        coordinates.length !== 2
      ) {
        setError("Invalid or missing coordinates");
        setIsLoading(false);
        return;
      }

      const [lat, lng] = coordinates;
      setMarkers([{ position: { lat, lng }, title: "Location" }]);
      setIsLoading(false);
    };

    loadCoordinates();
  }, [coordinates]);

  const handleOnLoad = () => {
    setScriptLoaded(true);
  };
  const containerStyle = {
    width: "100%",
    height: "330px",
  };

  if (isLoading) {
    return <Loader big={false} />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="w-full h-[18rem] sm:h-[20rem] mb-4">
      <LoadScript googleMapsApiKey={apiKey} onLoad={handleOnLoad}>
      {scriptLoaded && window.google && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={9}
          center={markers[0]?.position}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={marker.position}
              title={marker.title}
              icon={{
                url: AkiyaAkiya,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          ))}
        </GoogleMap>
        )}

      </LoadScript>
    </div>
  );
};

export default MapIComponent;
