import React from 'react';

import humanTemp from './../images/Miscs/humanTemp.png'


const Test2 = () => {
  return (
    <div className="text-center p-8 rounded-lg mx-[15%]">
      <h2 className="text-left text-[20px] text-[rgb(180,180,180)] mb-4">WHAT OUR CLIENTS SAY</h2>
      <p className=" text-[#5ab963] mb-4 text-[25px]">
      “Again thank you, honestly amazing job, <br />
we are so very happy.<br />
I think 3 months is excellent from 1st contact to keys”
      </p>
      <div className='flex justify-center items-center'>
        {/* <div className='w-[3rem] h-[3rem]'>
        <img src={humanTemp} className="rounded-full w-full h-full" alt="" />

        </div> */}
        <div>
        <p className="font-[300]">Aily and Warner Lamb</p>

        </div>

      </div>
    </div>
  );
};

export default Test2;
