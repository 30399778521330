import React, { useEffect, useState } from "react";
import HeadingContainer from "../headingContainer/HeadingContainer";
import {
  headingData,
  eventData,
  sliderImages,
  headingForAllData,
} from "./useCommunityHook";
import CardSlider from "../cardSlider/CardSlider";
import SliderImagesContainer from "../sliderImagesContainer/SliderImagesContainer";
import QuestionBox from "../QuestionBox/QuestionBox";
import BlogCard from "../blogCard/BlogCard";
import EventCard from "../EventCard/EventCard";
import CustomButton from "../CustomButton/CustomButton";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import { fetchData } from "../../Utils/CommonFunctions";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import NewsCard from "./../AkiyaOnNews/NewsCards/NewsCard";
import CardSlider2 from "./../AkiyaOnNews/NewsCards/customCardSlider";

const Community = () => {
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [latestNews, setLatestNews] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const blogResponse = await fetchData("https://app.akiya2.com/blogs");
        const newsResponse = await fetchData("https://app.akiya2.com/all_news");

        const sortedBlogData = blogResponse
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setLatestBlogData(sortedBlogData);
        setLatestNews(newsResponse);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <div className="w-full">
      <HeadingForAll
        smallHeading={headingForAllData.subheading}
        bigHeading={headingForAllData.heading}
      />

      <div>
        <div className="w-[100vw] flex justify-center items-center">
          <div className="max-w-[1440px] mt-[3rem]">
            <h1 className="dark-header mb-0">Akiya2.0 in the news</h1>
            <h1 className="paragraph2 mx-[30px] my-0">
              Discover the buzz around Akiya2: reviving Japan's akiyas. Follow
              for updates on our mission.
            </h1>
            {loading ? ( // Show loader while data is being fetched
              <Loader />
            ) : (
              <div className="w-full flex items-center justify-center my-10">
                <div className="w-[95%]">
                  <CardSlider2
                    data={latestNews}
                    Card={NewsCard} // Assuming you have a NewsCard component
                    endValues={{ xs: 1, md: 2, lg: 3 }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex justify-center mt-20">
          <CustomButton
            text={"Read All News"}
            handleButtonClick={() => navigate("/news")}
          />
        </div>
      </div>

      <div className="w-full flex justify-center mb-12">
        <div className="flex flex-col justify-center max-w-[1440px]">
          <div className="mt-10  lg:mx-[3rem] flex flex-col gap-10 md:mx-[2rem] mx-[1rem]">
            {/* <HeadingContainer data={headingData[0]} />
            <div id="events"></div>
            <CardSlider
              data={eventData}
              Card={EventCard}
              endValues={{
                xs: 1,
                md: 2,
                lg: 3,
              }}
            /> */}
          </div>

          <div className="max-w-[1400px] mx-8"></div>

            <div className="max-w-[1440px] mt-[3rem]">
              <div className="mb-6 mx-[30px] ">
                <HeadingContainer data={headingData[1]} />
              </div>
              {latestBlogData.length ? (
                <div className="w-full flex items-center justify-center my-10">
                  <div className="w-[95%]">
                    <CardSlider2
                      data={latestBlogData}
                      Card={BlogCard}
                      endValues={{
                        xs: 1,
                        md: 2,
                        lg: 3,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <Loader big={false} />
              )}

              <div className="w-full flex justify-center mt-20">
                <CustomButton
                  text={"Read All Blogs"}
                  handleButtonClick={() => navigate("/blogs")}
                />
              </div>
              <div className="my-[5rem]"></div>

              <div className="w-full">
                <QuestionBox />
              </div>
            </div>


          <div className="mt-16 ">
            <SliderImagesContainer images={sliderImages} />
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="max-w-[1280px] md:mb-8">
              <HeadingContainer data={headingData[2]} center={true} />
            </div>
            <HashLink className="w-full md:w-auto" smooth to="/internship/#internform">
              <CustomButton text={"Internship programmes"} />
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
