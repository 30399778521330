export const howItWorksData = {
    heading: "A complete soup to nuts akiya consultation ecosystem",
    paragraph: (
      <>
        Our experts will help you buy and renovate your Akiya! Akiya2.0’s
        consultancy service will pair you with the perfect kominka that is right
        for you. We use our connections and resources in Japan to connect you to
        your authentic Japanese property without the hassle of researching akiya
        and working with municipal governments. We enable both you and the
        community to meaningfully activate properties, benefitting our clients
        with up-and-coming real estate, and the community surrounding it by
        reviving an empty property.
      </>
    ),
    firstHeading: "Our services - How It Works",
    secondHeading: "Your way into rural Japanese real estate",
    services: [
      {
        title: "1) Find and Purchase your Akiya",
        items: [
          "Search Assistance",
          "Market Analysis (Optional)",
          "Site Inspection",
          "Regulatory Advisory",
          "Buyer Side Negotiations",
          "Contract Liaison",
          "Other Language Services",
        ],
        position: "top-right",
      },
      {
        title: "2) Renovate your dream Japanese home",
        items: [
          "Project Scoping",
          "Visualisation Service",
          "Costing/ Contractors/ Timetable",
          "Renovation and monitoring",
          "Contractor management",
          "Buyer Visit Coordination",
          "Moving in/ Local Activation",
        ],
        position: "bottom-right",
      },
      {
        title: "3) Activate/ Operate your Akiya investment",
        items: [
          "Market Analysis",
          "Marketing",
          "Property Management",
          "Reservation Management (Optional)",
          "Corporate Presence (Optional)",
        ],
        position: "bottom-left",
      },
      {
        title: "4) Leave your Akiya better than you first invested in it.",
        items: [
          "Marketing and Listing",
          "Moving out/Deep Clean",
          "Contract Liaison",
        ],
        position: "top-left",
      },
    ],
  };