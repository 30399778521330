import React from 'react'
import Properties_listing from './Properties_listing'
import { Link } from 'react-router-dom';

const intro = "Introducing our new properties, sustainably refurbished from traditional Japanese Akiya."
const aboutIntro="Where you might be staying your next holiday out to Japan"
const secondIntro = "There are more than 8 million akiya sitting vacant, waiting for you"
const aboutSecondIntro = "Here's the fuss-free way to actually own one."
function  SecondSOV() {
  return (
    <div className="pt-4">
      <h1 className="dark-header mx-[30px]">{intro}</h1>
      <h2 className="light-header flex ">{aboutIntro}</h2>
      <Properties_listing />
      <div className="flex flex-col md:flex-row my-[5rem]">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <h1 className="dark-header mr-[30px]">{secondIntro}</h1>
          <h1 className="light-header mr-[30px]">{aboutSecondIntro}</h1>
        </div>
        <div className="w-full md:w-1/2 flex justify-center items-center">
          <Link className='w-full md:w-auto' to="/services/how-it-works">
            <button className="py-[15px] px-[30px] w-full bg-[#5ab963] rounded text-white text-[16px] font-[800]">
              Our Services
            </button>
          </Link>
        </div>
      </div>
    </div>

  )
}

export default SecondSOV