import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//500
const ProfileBLogCard = ({ item }) => {
  const { title, imageUrls, date, content, index } = item;
    const navigate=useNavigate();
  return (
    <div className="  flex shadow-2xl justify-around  cursor-pointer   "
    onClick={()=>navigate(`/blogs/${index}`)}>
      <div className="flex flex-col ">
        <div className=" h-[15rem]    ">
          {/* Set a fixed width and height for the image */}
          <img
            loading="lazy"
            src={imageUrls}
            alt="Event"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="mx-3">
          <p className="mt-2 text-[--medium-sea-green]">{date}</p>
          <p className="mt-2 font-bold text-xl mb-2">{title}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10  ">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileBLogCard;
