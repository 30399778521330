const images = [];

function importAll(r) {
  r.keys().forEach((key) => images.push(r(key)));
}

try {
  importAll(
    require.context(
      "../../Components/images/grow-your-akiya",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (error) {
  console.error(error);
}
export const headingData = [
  {
    heading: "With Akiya2.0, get set to become a sustainable Japan investor.",
    info: "Discover the possibilities of investing in Japanese rural real estate with our specialized investor services. We navigate complexities, providing guidance to unveil opportunities, simplify acquisitions, and guarantee a unique experience in the serene landscapes of rural Japan. Collaborate with us for a seamless and rewarding investment journey.",
  },
  {
    heading: "Own a property in Japan? Grow your akiya today.",
    info: "Exploring ways to enhance your Akiya? Discover our tailored services  and connect with us for personalized support and inquiries. Let's nurture your Akiya together!",
  },
];

export const subHeadingdata = [
  {
    number: "01",
    title: "Investment Data Analytics",
    subtitle: [
      "In-Depth Market Analysis",
      "Risk Assessment Strategies",
      "Sustainable Investment Commitment",
    ],
    content: [
      "Our team delivers thorough market analysis, providing profound insights into Akiya investment trends and potential opportunities. We will deliver a report on the status of the market and the viability of each akiya and their investment opportunity.",
      "Navigate investment risks confidently with our analytics, empowering you to implement effective strategies for mitigating potential challenges.",
      "Akiya2.0 is committed to making revitalising Japan and its local regions. Partnering with us means a commitment to eco-friendly and socially responsible investment, aligning your financial gains with Japan's long-term prosperity.",
    ],
    imageUrls: [images[0], images[1]],
  },
  {
    number: "02",
    title: "Airbnb/Booking Operator",
    subtitle: [
      "Effortless Listing Setup",
      "We Maximize Your Global Presence",
      "Optimized Rental Potential",
    ],
    content: [
      "We help simplify the Airbnb registration process for your Akiya property, ensuring a seamless and efficient setup for attracting potential renters.",
      "We facilitate Akiya registration with top booking operators, ensuring global expansion and diverse exposure. Trust our expertise for increased booking opportunities and enhanced property visibility.",
      "Let us guide you through strategic registration with key booking operators, such as Airbnb. Unlock the full rental potential of your Akiya, ensuring a steady stream of lucrative bookings for sustained success.",
    ],
    imageUrls: [images[2], images[3]],
  },
  {
    number: "03",
    title: "Media",
    subtitle: [
      "Introducing JapanTravel.com",
      "Visual Showcase",
      "Exposure Amplification",
    ],
    content: [
      "Entrust JapanTravel.com to skillfully showcase your Akiya property, weaving captivating narratives that engage and enchant diverse audiences.",
      "We leverage JapanTravel.com's Visual Media Platform to showcase your Akiya with aesthetic appeal, ensuring maximum impact on potential buyers or renters.      ",
      "Expand Akiya Exposure with JapanTravel.com, tap into Diverse Audiences Seeking Authentic Japanese Experiences through Our Media Channels.",
    ],
    imageUrls: [images[4]],
  },
  {
    number: "04",
    title: "Akiya2.0 Travel Club!",
    subtitle: [
      "Secure Akiya rentals",
      "Why Akiya2.0?",
      "Join the Akiya2.0 Community",
    ],
    content: [
      "Akiya2.0 ensures your property's safety. We offer a trusted platform for stress-free process, prioritizing your peace of mind throughout the entire rental process.",
      "We go beyond a travel club, seamlessly managing your Akiya. Enjoy secure rentals and exclusive travel experiences. Akiya2.0 makes your property a key to exploration.",
      "Rent your Akiya, and unlock exclusive travel for yourself. Immerse in a community of explorers. Your Akiya isn't just another investment —it's the gateway to adventure and connection with our travel club.",
    ],
    imageUrls: [images[5], images[6]],
  },
];
