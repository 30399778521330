import React from "react";
import "./TopSecion.css";
import HeadingForAll from "../../HeadingForAll/HeadingForAll";
import MidSection from "../MidSection/MidSection";
import Box from "../Box-on-image/Box";
import QuestionBox from "../../QuestionBox/QuestionBox";
function TopSection() {
    const firstHeading = "Our services";
    const secondHeading = "Your way into rural Japanese real estate";
    return (
        <div>
            <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
        <div className="w-[100vw] flex justify-center">
        <div className=" max-w-[1440px] lg:mx-[3rem]  md:mx-[2rem] mx-[1rem] flex flex-col gap-8  ">

<MidSection />
<Box />
<QuestionBox />


</div>
</div>


        </div>

    );
}

export default TopSection;