import React, { useEffect, useState } from "react";
import usePrefectureHook from "./usePrefectureHook";
import { useParams } from "react-router-dom";
import AkiyaCard from "../AkiyaCard/AkiyaCard";
import SortDropDown from "../sortDropDown/SortDropDown";
import MapComponent from "./Maps";
import Loader from "../Loader/Loader";
// import PageNotFound from "../extraPages/PageNotFound";
import CommingSoon from "../commingSoon/CommingSoon";
import axios from "axios";
import checkAndTrimData from "./checkers"; // Import the function

const Prefecture = () => {
  const { akiyaData, sortDropDownOptions, sortingType, handleFavoriteClick } =
    usePrefectureHook();
  const { prefecture_name } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20); // 5 columns * 4 rows = 20 items per page
  const [viewData, setViewData] = useState([]);
  const [sortBy, setSortBy] = useState("Sort By");
  const [orderBy, setOrderBy] = useState("Order By");
  const [allMarkers, setAllMarkers] = useState();
  
  const handleSortbyChange = (item) => {
    setSortBy(item.display);
  };

  const handleOrderByChange = (item) => {
    setOrderBy(item.display);
  };

  useEffect(() => {
    async function getAllMArkers() {
      const response = await axios.get("https://app.akiya2.com/all_listing");
      console.log("response from the server: ");
      console.log(response);

      setAllMarkers(response.data);
    }

    getAllMArkers();
  }, []);

  useEffect(() => {
    if (akiyaData) {
      // Filter the akiyaData using the checkAndTrimData function
      const filteredData = checkAndTrimData(akiyaData);
      let sortedDataCopy = [...filteredData]; // Make a copy of the filtered data

      // Apply sorting based on the current sortBy and orderBy states
      if (sortBy === "By Price") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => {
            const aPrice = extractPriceValue(a.Price);
            const bPrice = extractPriceValue(b.Price);
            if (aPrice && bPrice) {
              return bPrice - aPrice;
            } else if (aPrice) {
              return -1;
            } else if (bPrice) {
              return 1;
            } else {
              return 0;
            }
          });
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => {
            const aPrice = extractPriceValue(a.Price);
            const bPrice = extractPriceValue(b.Price);
            if (aPrice && bPrice) {
              return aPrice - bPrice;
            } else if (aPrice) {
              return -1;
            } else if (bPrice) {
              return 1;
            } else {
              return 0;
            }
          });
        }
      } else if (sortBy === "By Area") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => {
            const aArea = parseFloat(a.Area_of_Land);
            const bArea = parseFloat(b.Area_of_Land);
            if (aArea && bArea) {
              return bArea - aArea;
            } else if (aArea) {
              return -1;
            } else if (bArea) {
              return 1;
            } else {
              return 0;
            }
          });
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => {
            const aArea = parseFloat(a.Area_of_Land);
            const bArea = parseFloat(b.Area_of_Land);
            if (aArea && bArea) {
              return aArea - bArea;
            } else if (aArea) {
              return -1;
            } else if (bArea) {
              return 1;
            } else {
              return 0;
            }
          });
        }
      } else if (sortBy === "By Year") {
        if (orderBy === "Higher to Lower") {
          sortedDataCopy.sort((a, b) => {
            const aYear = a.Year_of_Construction;
            const bYear = b.Year_of_Construction;
            if (aYear && bYear) {
              return bYear - aYear;
            } else if (aYear) {
              return -1;
            } else if (bYear) {
              return 1;
            } else {
              return 0;
            }
          });
        } else if (orderBy === "Lower to Higher") {
          sortedDataCopy.sort((a, b) => {
            const aYear = a.Year_of_Construction;
            const bYear = b.Year_of_Construction;
            if (aYear && bYear) {
              return aYear - bYear;
            } else if (aYear) {
              return -1;
            } else if (bYear) {
              return 1;
            } else {
              return 0;
            }
          });
        }
      }
      setViewData(sortedDataCopy);
      setCurrentPage(1); // Reset current page to the first page after sorting
    }
  }, [akiyaData, sortBy, orderBy]);

  const extractPriceValue = (priceString) => {
    // Extract the numerical value from the 'Price' string
    if (typeof priceString !== "string") {
      return 0; // Return 0 if priceString is not a string
    }
    const numericValue = parseFloat(priceString.replace(/[^\d.-]/g, ""));
    return isNaN(numericValue) ? 0 : numericValue;
  };

  // const extractArea = (area) => {
  //   if (area) {
  //     let house = area.replace("m", "").replace(" ", "");
  //     const isLastCharDigitHouse = !isNaN(house.slice(-1));
  //     const floating = parseFloat(area);
  //     return floating;
  //   } else {
  //     return 0;
  //   }
  // };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = viewData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  console.log("akiyaData");
  console.log(akiyaData);

  return (
    <div className="flex justify-center gap-3 my-2rem">
      <div className="max-w-1440px lg:mx-3rem md:mx-2rem mx-1rem w-[97%] flex flex-col">
        {akiyaData && akiyaData.length > 0 && (
          <div>
            <h1 className="mt-12 text-[2rem] font-bold text-left text-[#333333]">{`${prefecture_name} Listing`}</h1>
            <div className="map mb-4 h-[30rem] bg-slate-500 w-full">
              {console.log("00000000000")}
              {console.log(akiyaData)}

              <MapComponent
                akiyaData={allMarkers}
                prefectureName={prefecture_name}
                apiKey={"AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ&"}
              />
            </div>
            {akiyaData.length ? (
              <div className="w-full 400">
                <div className="w-full h-[4rem] flex justify-end items-center">
                  <div className="w-full md:w-[50%]  flex justify-end gap-3">
                    <div className="flex gap-2">
                      {sortDropDownOptions && (
                        <SortDropDown
                          options={sortDropDownOptions}
                          text={sortBy}
                          handleChange={handleSortbyChange}
                          color={{ primary: "#5ab963" }}
                        />
                      )}
                      {sortDropDownOptions && (
                        <SortDropDown
                          options={sortingType}
                          text={orderBy}
                          handleChange={handleOrderByChange}
                          color={{ primary: "#5ab963" }}
                        />
                      )}
                    </div>
                    <button
                      className={`custom-button bg-[var(--medium-sea-green)] hover:bg-[var(--sea-green)] lg:px-7 md:px-6 sm:px-6 inline-block rounded-md text-white font-semibold  mr-2`}
                    >
                      <button
                        className="flex  stroke-green-500"
                        onClick={handleFavoriteClick}
                      >
                        {`Your Favorites `}
                      </button>
                    </button>
                  </div>
                </div>

                <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 mx-3 sm:m-0   gap-4 mt-5 justify-center ">
                  {currentItems &&
                    currentItems.map((item) => (
                      <AkiyaCard key={item.id} item={item} />
                    ))}
                </div>
                <div className="flex justify-evenly mt-10 mb-5">
                  <button
                    className={`custom-button bg-[var(--sea-green)] inline-block   text-white font-semibold py-2 px-3`}
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous Page
                  </button>
                  <button
                    className={`custom-button bg-[var(--sea-green)] inline-block   text-white font-semibold py-2 px-3`}
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentItems.length < itemsPerPage}
                  >
                    Next page
                  </button>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        )}
        {!akiyaData && <Loader />}

        {akiyaData && akiyaData.length === 0 && (
          <CommingSoon prefectureName={prefecture_name} />
        )}
      </div>
    </div>
  );
};

export default Prefecture;

