import React from 'react';
import japanTravel from "../../images/about/japantravel.png";
import o2ostays from "../../images/about/o2ostays.png";
import suzu from "../../images/about/suzu.png";
import wajima from "../../images/about/wajima.png";
import kominka from "../../images/about/kominka.png";
import kominka1 from "../../images/about/kominka1.jpg";
import imabari from "../../images/about/imabari.png";

const LogoSlider = () => {
  const logos = [
    japanTravel,
    o2ostays,
    suzu,
    wajima,
    kominka,
    kominka1,
    imabari,
  ];

  return (
    <div className="relative overflow-hidden bg-white py-4 sm:pb-8 md:pb-12 lg:pb-16 max-w-[100%]">
      <div className="relative flex animate-slide whitespace-nowrap">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo}
            alt={`Logo ${index + 1}`}
            className="inline-block h-8 mx-4 sm:h-10 sm:mx-6 md:h-12 md:mx-8 lg:h-16 lg:mx-10"
          />
        ))}
        {logos.map((logo, index) => (
          <img
            key={`copy-${index}`}
            src={logo}
            alt={`Logo ${index + 1}`}
            className="inline-block h-8 mx-4 sm:h-10 sm:mx-6 md:h-12 md:mx-8 lg:h-16 lg:mx-10"
          />
        ))}
      </div>
      <div className="absolute inset-y-0 left-0 w-48 bg-gradient-to-r from-white to-transparent"></div>
      <div className="absolute inset-y-0 right-0 w-48 bg-gradient-to-l from-white to-transparent"></div>
    </div>
  );
};

export default LogoSlider;