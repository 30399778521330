import React, { useState, useEffect } from "react";
import Single_property from "./Single_property";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../Loader/Loader";

function Properties_listing() {
  const [properties, setProperties] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);
  const prevBu = "<<"
  const nextBu = ">>"

  useEffect(() => {
    axios
      .get("https://app.akiya2.com/all_properties")
      .then((response) => {
        console.log(response.data);
        setProperties(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div
        className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
        onClick={onClick}
      >
        <button className="next-btn absolute top-1/2 transform -translate-y-1/2 right-1 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pl-[1rem] hover:translate-x-[0.5rem] transition-all text-[20px]">
          &#10095;
        </button>
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div
        className="absolute top-1/2 left-[-0.7rem] transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
        onClick={onClick}
      >
        <button className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-[white] bg-[#5ab963] opacity-75 rounded p-[0.5rem] hover:opacity-100 hover:pr-[1rem] hover:translate-x-[-0.5rem] transition-all text-[20px]">
          &#10094;
        </button>
      </div>
    );
  };

  const BottomNextButton = () => {
    return (
      <button
        className="block md:hidden bg-[#5ab963] font-[800] text-white rounded p-2 mr-[1.2rem] ml-1"
        onClick={() => sliderRef.slickNext()}
      >
        {nextBu}
      </button>
    );
  };

  const BottomPrevButton = () => {
    return (
      <button
        className="block md:hidden bg-[#5ab963] font-[800] text-white rounded p-2 ml-[1.6rem]"
        onClick={() => sliderRef.slickPrev()}
      >
        {prevBu}
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, // Disable default arrows for mobile view
        },
      },
    ],
  };

  return (
    <div className="max-w-[100vw] flex flex-col items-center">
      <div className="mx-[15px] md:mb-[4rem] mt-[1rem] w-full max-w-[1440px] relative">
        {properties.length > 0 ? (
          <>
            <Slider {...settings} className="w-full" ref={setSliderRef}>
              {properties.map((property) => (
                <div className="px-[10px]" key={property.serialNumber}>
                  <a
                    href={`/property/${property.serialNumber}`}
                    className="block md:hidden"
                  >
                    <Single_property property={property} hideKnowMore={true} />
                  </a>
                  <div className="hidden md:block">
                    <Single_property property={property} hideKnowMore={false} />
                  </div>
                </div>
              ))}
            </Slider>
            <div className="flex justify-center items-center w-[100vw] md:hidden translate-y-[-100%]">
              <BottomPrevButton />
              <BottomNextButton />
            </div>
          </>
        ) : (
          <Loader />

        )}
      </div>
    </div>
  );
}

export default Properties_listing;
