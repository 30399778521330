import React from "react";
import MapComponent from "./MapIndividualProp";

function FirstSection(props) {
  const data = props.data;

  return (
    <div className="flex flex-col lg:flex-row max-h-[100%]">
      {/* Left Section */}
      <div className="w-full lg:w-[55%] lg:pr-8">
        <div>
          <h1 className="text-[24px] leading-[36px] font-[800]">
            {data.smallAdress}
          </h1>
          <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
            {data.about}
          </p>
        </div>

        <div>
          <h1 className="text-[24px] leading-[36px] font-[800]">Access</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="mb-4 lg:mb-0">
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                {data.nearestAirport}
              </p>
            </div>
            <div className="mb-4 lg:mb-0">
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                {data.distanceFromAirport}
              </p>
            </div>
            <div className="mb-4 lg:mb-0">
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                Check In Time: 3:00 PM
              </p>
            </div>
            <div>
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                Check Out Time: 10:00 AM
              </p>
            </div>
          </div>
        </div>

        <div>
          <h1 className="text-[24px] leading-[36px] font-[800]">Details</h1>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="mb-4 lg:mb-0">
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                Size: {data.size}m<sup>2</sup>
              </p>
            </div>
            <div>
              <p className="text-justify text-[24px] leading-[36px] font-[300] text-[#949995]">
                Accommodate: {data.accommodation}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section - Maps */}
      <div className="h-[100%] w-full lg:w-[45%] flex justify-center items-center mt-8 lg:mt-0">
      <MapComponent apiKey={"AIzaSyDhd_bCluPIx2cSHS7pGd5wVPkIRU7rpxQ&"} address={data.fullAdress}/>
      </div>
    </div>
  );
}

export default FirstSection;
