import React from "react";
import useAkiyaSearchHook from "./useAkiyaSearchHook";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import HeadingContainer from "./HeadingContainerAS";
import CustomButton from "../CustomButton/CustomButton";
import CustomDropDown from "../customDropDown/CustomDropDown";
import AkiyaCard from "../AkiyaCard/AkiyaCard";
import CardSlider from "../cardSlider/CardSlider";
import QuestionBox from "../QuestionBox/QuestionBox";
import Loader from "../Loader/Loader";
import japanMap from "../images/akiyaSearch/jp.svg";
import kominkaImages from "../images/akiyaSearch/kominkaImage.jpg";
import favImage from "../images/akiyaSearch/favImage.avif";
import useWindowDimensions from "../useWindowDimensions";

const AkiyaSearch = () => {
  const {
    headingForAllData,
    headingData,
    allPrefectures,
    listingData,
    selectedAkiya,
    handleFavoriteClick,
    handleKominkaClick,
  } = useAkiyaSearchHook();
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 768; // Considering 'md' breakpoint as 768px

  // Define the array of prefectures to highlight
  const somePrefectures = [
    "Ishikawa",
    "Kyoto",
    "Tokyo",
    "Osaka",
    "Hiroshima",
    "Chiba",
    "Fukuoka",
  ];

  return (
    <div className="w-full">
      <HeadingForAll
        smallHeading={headingForAllData.subheading}
        bigHeading={headingForAllData.heading}
      />
      <div className="flex justify-center">
        <div className="w-full max-w-[1440px] relative">
          <div className="flex md:flex-row flex-col gap-10 mx-10 max-h-fit h-fit mt-[1rem] relative">
            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[0] && (
                <div>
                  <img src={japanMap} alt="" className="h-[20rem] w-full" />
                  <HeadingContainer data={headingData[0]} />
                </div>
              )}
              <div className="flex justify-center md:justify-start">
                {allPrefectures && (
                  <CustomDropDown
                    options={allPrefectures}
                    highlightPrefectures={somePrefectures} // Pass the highlight prefectures array
                  />
                )}
              </div>
            </div>

            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[2] && (
                <div>
                  {isSmallScreen ? (
                    <a href="/kominka">
                      <img
                        src={kominkaImages}
                        alt=""
                        className="h-[20rem] w-full"
                      />
                      <HeadingContainer data={headingData[2]} />
                    </a>
                  ) : (
                    <>
                      <img
                        src={kominkaImages}
                        alt=""
                        className="h-[20rem] w-full"
                      />
                      <HeadingContainer data={headingData[2]} />
                    </>
                  )}
                </div>
              )}
              {!isSmallScreen && (
                <div className="opacity-0 md:opacity-100 flex justify-center md:justify-start mt-10">
                  <CustomButton
                    handleButtonClick={handleKominkaClick}
                    text={"Check it out!"}
                  />
                </div>
              )}
            </div>

            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[1] && (
                <div>
                  {isSmallScreen ? (
                    <div
                      onClick={handleFavoriteClick}
                      className="cursor-pointer"
                    >
                      <img src={favImage} alt="" className="h-[20rem] w-full" />
                      <HeadingContainer data={headingData[1]} />
                    </div>
                  ) : (
                    <>
                      <img src={favImage} alt="" className="h-[20rem] w-full" />
                      <HeadingContainer data={headingData[1]} />
                    </>
                  )}
                </div>
              )}
              {!isSmallScreen && (
                <div className="opacity-0 md:opacity-100 flex justify-center md:justify-start">
                  <CustomButton
                    handleButtonClick={handleFavoriteClick}
                    text={"Your favorites"}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt-20 mx-5 mb-20">
            {headingData && headingData[0] && (
              <div className="ml-[30px] font-[700] text-[26px]">
                {headingData[3].heading}
              </div>
              // <HeadingContainer data={headingData[3]} />
            )}
          </div>

          <div className="w-full flex items-center justify-center my-10">
            <div className="w-[90%] lg:mr-[4rem] md:mr-[2.5rem] sm:mr-[1.5rem] mr-[1rem]">
              {selectedAkiya ? (
                <CardSlider
                  data={selectedAkiya}
                  Card={AkiyaCard}
                  endValues={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 5,
                  }}
                />
              ) : (
                <Loader big={false} />
              )}
            </div>
          </div>
          <QuestionBox />
        </div>
      </div>
    </div>
  );
};

export default AkiyaSearch;
