import React from "react";
import GreenBoxes from "./GreenBoxes";

const Renovation = () => {
  const dark_heading = "Renovation Services";
  const dark_heading_2 = "How much does a renovation cost?";
  const light_heading = (
    <>
      Whether you are looking for an architect to fully bring to live your dream
      home; or local contractors/craftsmen to assist you on your own design
      project, Akiya2.0 can take care of it for you. Our in-house team of
      architects are experienced in design and in managing local craftsmen from
      all parts of Japan - we speak English, Japanese and Architecture-ese so
      your ideas and instructions are communicated correctly every time.
      <br />
      <br />
      Some of our services at this stage include :
    </>
  );
  const light_heading_2 = (
    <>
      Project Scoping
      <br />
      Costing/ Contractors/ Timetable <br />
      Renovation and Monitoring + Contraction Management <br />
      Visualisation Service <br />
      Buyer Visit Coordination <br />
      Moving in/ Local Activation
    </>
  );

  const boxData = [
    {
      id: "firstBox",
      heading: "Renovation Fees (To contractor/architect)",
      content: (
        <>
          A number of variables go into your deciding your renovation cost
          (construction and architect/contractor). These include size of
          project, age and condition of akiya (old akiya need a bit more love),
          location and desired quality. Also, you may have to invest into safety
          measures if you want to rent out your Akiya.
          <br />
          <br />
          We know you want to hear something other than just “it depends”. As a
          very rough guide, an Akiya renovation costs 50,000yen to 100,000yen
          per square meter. In our experience, a 200 sqm Akiya will require 10-
          20 million yen for renovation.
        </>
      ),
    },
    {
      id: "secondBox",
      heading: "Akiya2.0’s Standard Project Management ",
      content: (
        <>
          What goes into our Standard Project Management service? Everything you
          need to start and end the entire renovation process, for a basic
          Akiya.
          <br />
          <br />
          Project Scoping + Costing/ Contractors/ Timetable + Renovation and
          Monitoring + Contraction Management; all in English and with meetings
          (both languages) scheduled once every 2-4 weeks during the entire
          renovation phase.
          <br />
          <br />
          5% of renovation (construction) fee for Project Management
        </>
      ),
    },
    {
      id: "MainBox",
      smallHeader: "Standard Renovation + Project Management Services:",
      bigHeader: "Starting at 10 million (Renovation) + 5% fee",
    },
  ];

  return (
    <div className="py-3 h-full">
      <h1 className="dark-header text-2xl sm:text-3xl md:text-3.5xl">
        {dark_heading}
      </h1>
      <p className="light-header text-lg sm:text-xl md:text-2xl md:leading-9">
        {light_heading}
      </p>
      <p className="mx-4 sm:mx-6 md:mx-8 lg:mx-12 font-bold mb-8 text-base sm:text-lg md:text-xl md:leading-8">
        {light_heading_2}
      </p>
      <h1 className="dark-header text-2xl sm:text-3xl md:text-4xl mt-[5rem]">
        {dark_heading_2}
      </h1>
      <GreenBoxes
        data={boxData}
        color={["rgb(80,131,193)", "rgb(58,127,77)"]}
      />{" "}
    </div>
  );
};

export default Renovation;
