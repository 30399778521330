import React from "react";
import "./Boxes.css";

function Boxes() {
  const header = "Who are we looking for?";

  const services = [
    {
      id: "purchase",
      header: "Social Media Intern",
      link: "/buy-an-akiya",
      paragraph: (
        <>
          Elevate your social media with our 3-week to 2-month Social Media Internship in Noto.
          Capture experiences on Akiya2.0 and JT Instagram, engage in 2-6 monthly house visits,
          and craft 15 shorts. Partner with Japan Travel for tours, enjoying Akiya2.0's support.
          Boost your social media skills in this dynamic internship.
        </>
      ),
    },
    {
      id: "grow",
      header: "Videographer Intern",
      link: "/grow-your-akiya",
      paragraph: (
        <>
          Embark on a 3-week to 2-month Video Making Internship in Noto. Capture Akiya2.0 experiences with videos and
          <br />
          Creating long-form videos for us. Partner with Japan Travel, explore,
          and receive Akiya2.0's support. Elevate your skills in this immersive internship.
        </>
      ),
    },
    {
      id: "purchase",
      header: "Architecture Intern",
      link: "/buy-an-akiya",
      paragraph: (
        <>
          Explore Japanese architecture with our 1-3 month Noto Architecture Internship.
          Document on Akiya2.0, engage in 1-4 monthly experiences.
          <br />
          Collaborate on house projects with our construction partners.
          Make models, and attend site visits. Connect locally and elevate your skills in this immersive internship.
        </>
      ),
    },
    {
      id: "purchase",
      header: "Japanese Studies Intern",
      link: "/buy-an-akiya",
      paragraph: (
        <>
          Explore Japan in our 1-2 month Language Internship in Noto.
          Document your cultural immersion on social media, contribute to business projects,
          and collaborate with partners like JapanTravel and other local vendors.
          <br />
          Relative fluency in Japanese is required for this internship
        </>
      ),
    },
  ];

  return (
    <div id="interns" className="img h-full max-w-[100%]">
      <div className="flex justify-center">
        <h1 className="text-white text-3xl font-bold leading-10 mt-5">
          {header}
        </h1>
      </div>
      <div className="flex flex-wrap justify-center mx-2 mt-8 pb-16">
        {services.map((service) => (
          <div key={service.id} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-8">
            <div className="h-full bg-black bg-opacity-75 p-4 rounded-lg">
              <h1 className="text-green-500 text-2xl mb-4">{service.header}</h1>
              <p className="text-sm text-gray-400">{service.paragraph}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Boxes;
