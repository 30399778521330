import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../useWindowDimensions";
import DropdownIcon from "../Svgs/Dropdown";

const CustomDropDown = ({ options, highlightPrefectures }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const { width } = useWindowDimensions(true);
  const [isBig, setIsBig] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [prefectureList, setPrefectureList] = useState(options);

  useEffect(() => {
    setIsBig(width >= 770);
  }, [width]);

  useEffect(() => {
    setPrefectureList(options); // Update prefectureList whenever options change
  }, [options]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    navigate(`/prefecture-listing/${option.prefecture_name}`);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSearching(false);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 500);
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    if (searchQuery === "") {
      setIsSearching(false);
      setPrefectureList(options);
    } else {
      setIsSearching(true);
      const sortedData = options.sort((a, b) => {
        const aStartsWithQuery = a.prefecture_name
          .toLowerCase()
          .startsWith(searchQuery);
        const bStartsWithQuery = b.prefecture_name
          .toLowerCase()
          .startsWith(searchQuery);
        if (aStartsWithQuery && !bStartsWithQuery) {
          return -1; // a comes before b
        } else if (!aStartsWithQuery && bStartsWithQuery) {
          return 1; // b comes before a
        } else {
          return 0; // maintain original order
        }
      });
      const filteredData = sortedData.filter((option) =>
        option.prefecture_name.toLowerCase().includes(searchQuery)
      );
      setPrefectureList(filteredData);
    }
  };

  return (
    <div
      className="relative inline-block transition-all duration-300 z-10"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${isDropdownOpen ? "rounded-b-none" : ""}  w-[100vw] md:w-[12rem] md:rounded-md custom-button flex justify-center items-center bg-[var(--medium-sea-green)] text-white font-semibold h-[4rem] cursor-pointer transition-all duration-300`}
        onClick={() => setSelectedOption(null)}
      >
        <p>All Prefectures</p>
        <DropdownIcon />
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className={`absolute bg-[var(--medium-sea-green)] top-full left-0 text-white border-gray-300 shadow-lg md:rounded-r-lg rounded-b-lg
          ${
            isBig
              ? isSearching
                ? "w-[195px] grid-cols-1"
                : "w-[700px] grid-cols-4"
              : "w-full max-h-[300px] overflow-y-auto"
          } grid z-50 transition-all duration-300`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input
            placeholder="Search Prefecture.."
            type="text"
            onChange={handleSearch}
            className="bg-[var(--medium-sea-green)] px-3 placeholder:text-green-200 text-white-400 text-sm ml-1 rounded-lg border-[3px] border-white outline-none"
          />
          {prefectureList &&
            prefectureList.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`cursor-pointer text-sm text-center py-2 w-full ${
                  highlightPrefectures.includes(option.prefecture_name)
                    ? "bg-[var(--medium-sea-green)] text-white hover:bg-[var(--sea-green)] font-[600]"
                    : "bg-[#6eaa6e] text-[#99d4a2e7]"
                } transition-all duration-300`}
              >
                {option.prefecture_name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;