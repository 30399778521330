import React from "react";
import HeadingContainer from "../headingContainer/HeadingContainer";
import {
  headingData,
  slidingBoxRight,
  slidingBoxLeft,
  imgContentData,
} from "./useAkiyaConcierge";
import WithLeftBox from "../SlidingImgWithBox/WithLeftBox";
import WithRightBox from "../SlidingImgWithBox/WithRightBox";
import QuestionBox from "../QuestionBox/QuestionBox";
import ImgContent from "../ImgContent/ImgContent";
import { Link } from "react-router-dom";
const AkiyaConcierge = () => {
  const header1 = "Everyone knows there are over 8 million akiya";
  const header2 = "Here's the fuss-free way to actually own one.";
  const { heading, info } = headingData[0];
  return (
    <>
      <div className="flex justify-center gap-3 my-[2rem]">
        <div className="max-w-[1440px] flex flex-col gap-8">
          <div className=" lg:mx-[3rem]  md:mx-[2rem] mx-[1rem]">
            <HeadingContainer data={headingData[0]} />
            {/* <h1 className="text-[2rem] font-[800]">{heading}</h1> */}
            {/* <p>{info}</p> */}
          </div>
          <div className="my-3">
            <WithRightBox data={slidingBoxRight} />
          </div>
          <div className=" flex lg:flex-row flex-col gap-5 lg:mx-[3rem]  md:mx-[2rem] mx-[1rem]">
            <div className="lg:w-[100%] w-full">
              <HeadingContainer data={headingData[1]} />
            </div>
            {/* <div className="lg:w-[50%] w-full">
              <HeadingContainer data={headingData[2]} />
            </div> */}
          </div>
          <div className="my-3">
            <WithLeftBox data={slidingBoxLeft} />
          </div>
          <div className=" flex lg:flex-row flex-col gap-5 lg:mx-[3rem]  md:mx-[2rem] mx-[1rem]">
            <div className="lg:w-[100%] w-full">
              <HeadingContainer data={headingData[3]} />
            </div>
            {/* <div className="lg:w-[50%] w-full">
              <HeadingContainer data={headingData[4]} />
            </div> */}
          </div>
          <div>
            <QuestionBox />
          </div>
          <div className="flex flex-col justify-center gap-10 mx-2">
            <ImgContent data={imgContentData[0]} />
            <ImgContent data={imgContentData[1]} />
          </div>
          <div className="flex mb-[3rem] mt-[6rem]">
            <div className="w-[50%]">
              <h1 className="dark-header mb-0">{header1}</h1>
              <h1 className="light-header mt-0">{header2}</h1>
            </div>
            <div className="w-[50%] flex justify-center items-center">
              <Link to={"/services/how-it-works"}>
              <button className="rounded py-[15px] px-[30px] bg-[#5ab963] font-[800] text-[16px] text-white leading-[30px]">
                Our Services
              </button>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AkiyaConcierge;
