import React, { useState, useEffect } from "react";
import CardSlider from "../../cardSlider/CardSlider";
import BlogCard from "../../blogCard/BlogCard";
import { fetchData } from "../../../Utils/CommonFunctions";

function ThirdSection() {
  const [latestBlogData, setLatestBlogData] = useState([]);
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetchData(`https://app.akiya2.com/blogs`);

        // Sort and slice the data
        const sortedData = response
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        console.log(sortedData);
        setLatestBlogData(sortedData);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    }

    getData(); // Call fetchData only once inside the useEffect
  }, []);
  return (
    <div>
      {latestBlogData.length && (
        <CardSlider
          data={latestBlogData}
          Card={BlogCard}
          endValues={{
            xs: 1,
            md: 2,
            lg: 3,
          }}
        />
      )}
    </div>
  );
}

export default ThirdSection;
