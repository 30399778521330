import React, { useState } from 'react';
import testimonials from './testimonialData';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="testimonial-carousel relative mx-[8rem] p-8">
      <h2 className="text-left font-semibold text-[rgb(180,180,180)] mb-4">WHAT THE PEOPLE SAY</h2>
      <div className="text-center p-8 rounded-lg mx-[15%] transition-opacity duration-1000 ease-in-out opacity-100">
        <p className="text-[#5ab963] italic mb-4 text-[22px]">
          {testimonials[currentIndex].text}
        </p>
        <div className='flex justify-center items-center'>
          <div className='w-[3rem] h-[3rem]'>
            <img src={testimonials[currentIndex].image} className="object-cover rounded-full w-full h-full" alt={testimonials[currentIndex].author} />
          </div>
          <div>
            <p className="font-[300] ml-1">{testimonials[currentIndex].author}</p>
          </div>
        </div>
      </div>
      <div className="absolute top-1/2 left-0 transform -translate-y-1/2 z-10 cursor-pointer">
        <button onClick={handlePrev} className="bg-[#5ab963] text-white rounded p-2 mx-2">&#10094;</button>
      </div>
      <div className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10 cursor-pointer">
        <button onClick={handleNext} className="bg-[#5ab963] text-white rounded p-2 mx-2">&#10095;</button>
      </div>
    </div>
  );
};

export default Testimonials;
