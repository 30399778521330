import React from "react";
import Page404 from "../Svgs/Page404.gif";
const PageNotFound = () => {
  return (
    <div className="flex justify-center">
      <div className="w-[100vh] relative h-[80vh] flex justify-center items-center red-400">
        <img src={Page404} className="w-[30rem] h-[30rem] "></img>
      </div>
    </div>
  );
};

export default PageNotFound;
