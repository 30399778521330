import React from "react";
import "../headingContainer/HeadingContainer.css";

const HeadingContainer = ({ data, center = false }) => {
  const { heading, info } = data;
  // console.log(data);
  return (
    <div className={`text-${center ? "center" : "left"}`}>
      <h1 className="heading">{heading}</h1>
     {info && <p className={`paragraph${center ? "1" : "2"}`}>{info}</p>}
    </div>
  );
};

export default HeadingContainer;
