import React from "react";
import { Link } from "react-router-dom";

const OuServiceIcon = () => {
  return (
    <div className="w-full max-w-[90vh] mx-auto">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 804 708"
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          fill="#265634"
          opacity="1.000000"
          stroke="none"
          d="
M368.997070,648.007324 
	C354.132080,645.517517 339.089417,643.792236 324.437195,640.387024 
	C288.339111,631.997681 255.466095,616.194214 225.263626,594.825562 
	C222.494232,592.866211 220.587128,592.977051 217.724762,594.526367 
	C160.742096,625.370422 92.750977,593.659729 80.676270,529.983948 
	C74.383484,496.799103 84.725540,468.155060 109.178238,444.851746 
	C112.176750,441.994171 112.654892,439.673370 111.503853,435.921631 
	C103.300446,409.183044 99.469765,381.741974 98.974380,353.197937 
	C90.320328,353.197937 81.793877,353.197937 72.247147,353.197937 
	C82.918785,342.115112 92.779099,331.874908 103.135529,321.254639 
	C110.814766,313.768250 118.116913,306.776428 125.121330,299.498077 
	C127.853401,296.659149 129.548248,296.722595 132.294937,299.530396 
	C140.389465,307.804993 148.786850,315.783295 157.244843,324.162842 
	C166.592072,333.737976 175.760162,343.027802 185.815994,353.217163 
	C175.535706,353.217163 166.486053,353.217163 157.083527,353.217163 
	C157.083527,355.065704 157.070557,356.370361 157.085480,357.674683 
	C157.307541,377.083679 160.360687,396.066315 165.232056,414.833771 
	C166.336807,419.089966 168.253799,420.108307 172.381790,420.152954 
	C232.700378,420.805084 275.964111,475.405914 263.143677,534.236938 
	C262.078156,539.126465 257.241821,544.343994 260.749847,548.904175 
	C263.821808,552.897461 269.224579,555.200378 273.792389,557.923462 
	C303.516205,575.643250 335.641235,586.340759 369.879395,590.835083 
	C360.502594,600.780701 351.209015,610.422241 340.512238,621.519531 
	C350.234558,630.560242 359.615784,639.283752 368.997070,648.007324 
z"
        />

        <path
          fill="#265634"
          opacity="1.000000"
          stroke="none"
          d="
M700.124756,351.222382 
	C708.004333,351.222382 715.883850,351.222382 723.763428,351.222382 
	C724.138733,351.869659 724.514038,352.516937 724.889343,353.164246 
	C715.370117,362.267273 705.850952,371.370300 695.845459,380.882080 
	C686.307922,390.258728 677.256592,399.226654 667.067017,409.322327 
	C657.799744,399.534698 648.998840,390.239655 640.060120,380.651917 
	C639.000000,379.476105 638.077820,378.592957 637.005615,377.418182 
	C628.509277,368.865448 620.162842,360.604309 610.764526,351.302032 
	C622.200256,351.302032 632.080017,351.302032 642.151489,351.302032 
	C642.333496,349.495300 642.615662,348.192535 642.572693,346.900543 
	C641.563538,316.538757 635.900513,287.165497 624.239929,258.998413 
	C622.676819,255.222519 620.989929,254.751678 617.212830,255.463348 
	C578.683899,262.722839 546.588623,251.341003 522.610535,220.317093 
	C503.421631,195.489700 499.618225,167.141647 507.947449,136.755951 
	C509.707886,133.120407 506.613678,132.941422 504.965118,132.135834 
	C479.648071,119.764320 452.888489,112.332489 425.083740,108.675926 
	C433.243988,100.769485 441.273956,93.172157 449.482208,85.406143 
	C439.782410,74.726288 430.427399,64.426048 421.072388,54.125816 
	C441.266418,55.175484 460.976990,59.079002 480.408813,64.442986 
	C500.202515,69.906845 519.102417,77.734138 537.305786,87.165672 
	C540.854858,89.004494 543.105042,88.506569 546.219238,86.495590 
	C600.118225,51.690002 671.550903,79.582115 687.254456,141.768433 
	C694.535217,170.600357 688.737915,197.164322 670.647461,221.617462 
	C670.297424,224.233963 669.764160,226.622574 670.563354,228.418045 
	C681.387268,252.733902 689.540039,277.879272 693.154968,304.271637 
	C695.116943,318.595917 695.771851,333.098999 697.031677,347.520081 
	C697.199646,349.442230 696.139709,352.460205 700.124756,351.222382 
z"
        />
        <path
          fill="#44925A"
          opacity="1.000000"
          stroke="none"
          d="
M640.197998,380.944580 
	C648.998840,390.239655 657.799744,399.534698 667.067017,409.322327 
	C677.256592,399.226654 686.307922,390.258728 695.661499,381.162598 
	C694.074951,403.848450 689.424805,426.114624 681.547119,447.579803 
	C676.742737,460.670898 670.745483,473.320679 665.556458,486.277802 
	C664.755432,488.278015 664.564758,491.333038 665.535522,493.113037 
	C686.182983,530.976074 679.192871,576.754883 648.101013,605.940002 
	C618.280334,633.931885 576.729980,639.519714 539.731812,620.226013 
	C535.831360,618.192078 532.987915,618.381165 529.184814,620.196228 
	C494.164642,636.910034 457.423828,647.215820 418.530914,649.273071 
	C412.230927,649.606262 405.937134,650.057068 398.655701,650.515503 
	C398.655701,658.809692 398.655701,667.260742 398.655701,675.711731 
	C398.229309,675.806152 397.802917,675.900574 397.376526,675.994995 
	C388.029968,666.855713 378.683380,657.716492 369.166931,648.292236 
	C359.615784,639.283752 350.234558,630.560242 340.512238,621.519531 
	C351.209015,610.422241 360.502594,600.780701 370.225037,590.715820 
	C379.606079,581.819397 388.558319,573.346252 398.449554,563.984375 
	C398.449554,574.683167 398.449554,584.092407 398.449554,594.070679 
	C433.250824,594.192627 465.903320,587.204956 497.131714,573.588684 
	C497.131714,572.921387 497.207581,572.569153 497.118805,572.265076 
	C496.699585,570.828613 496.242340,569.403137 495.786011,567.977783 
	C491.303253,553.976196 489.673798,539.704773 492.068268,525.130005 
	C497.395081,492.706360 514.671631,468.869537 544.205078,454.456604 
	C567.787537,442.947784 592.183105,442.599365 617.075378,451.394928 
	C620.869568,452.891907 621.056641,449.775024 621.897827,447.892456 
	C631.445801,426.525146 637.073792,404.074341 640.197998,380.944580 
z"
        />
        <path
          fill="#7FAB56"
          opacity="1.000000"
          stroke="none"
          d="
M420.805664,53.913189 
	C430.427399,64.426048 439.782410,74.726288 449.482208,85.406143 
	C441.273956,93.172157 433.243988,100.769485 424.804932,108.834122 
	C423.146545,110.437531 421.897247,111.573631 420.372253,112.889389 
	C412.334320,120.175613 404.572144,127.282188 395.805603,135.308258 
	C395.805603,125.390518 395.805603,117.013466 395.805603,107.978012 
	C390.221832,108.352135 385.125946,108.738342 380.024536,109.027527 
	C359.286255,110.203163 339.267822,114.850792 319.628357,121.399452 
	C316.800812,122.342285 315.791809,123.653542 316.435974,126.883934 
	C323.673981,163.182343 313.768250,194.032715 285.796417,218.353256 
	C264.367371,236.985123 238.994232,243.528412 211.028122,239.227570 
	C202.893875,237.976608 194.982025,235.279556 186.633255,233.160339 
	C178.656921,247.225723 171.531418,261.932404 167.286102,277.697662 
	C163.190887,292.905579 160.418976,308.469849 157.065704,323.877563 
	C148.786850,315.783295 140.389465,307.804993 132.294937,299.530396 
	C129.548248,296.722595 127.853401,296.659149 125.121330,299.498077 
	C118.116913,306.776428 110.814766,313.768250 103.301102,320.968658 
	C104.988510,307.959930 106.237556,294.681702 109.179420,281.789795 
	C115.966415,252.047729 127.556488,224.109299 143.395966,198.012756 
	C145.238998,194.976257 144.484985,192.798965 143.097672,190.237091 
	C135.814331,176.787415 132.405579,162.248611 132.512604,147.112885 
	C132.665344,125.512253 139.239151,105.819992 153.132843,89.087418 
	C173.100159,65.040192 198.947403,53.293804 230.211578,54.461601 
	C248.917084,55.160297 265.999115,61.237480 281.005798,72.646904 
	C283.681488,74.681206 286.103699,75.471428 289.599854,74.060013 
	C316.006866,63.399391 343.533844,57.173561 371.801178,54.211742 
	C379.240417,53.432262 386.761047,53.233570 394.247131,53.182198 
	C397.798126,53.157833 398.951752,51.872166 398.864532,48.518902 
	C398.722595,43.062592 398.826233,37.599888 398.826233,30.778698 
	C406.701447,39.092449 413.620209,46.396503 420.805664,53.913189 
z"
        />

        <path
          fill="#F8FAFC"
          opacity="1.000000"
          stroke="none"
          d="
M157.244858,324.162842 
	C160.418976,308.469849 163.190887,292.905579 167.286102,277.697662 
	C171.531418,261.932404 178.656921,247.225723 186.633255,233.160339 
	C194.982025,235.279556 202.893875,237.976608 211.028122,239.227570 
	C238.994232,243.528412 264.367371,236.985123 285.796417,218.353256 
	C313.768250,194.032715 323.673981,163.182343 316.435974,126.883934 
	C315.791809,123.653542 316.800812,122.342285 319.628357,121.399452 
	C339.267822,114.850792 359.286255,110.203163 380.024536,109.027527 
	C385.125946,108.738342 390.221832,108.352135 395.805603,107.978012 
	C395.805603,117.013466 395.805603,125.390518 395.805603,135.308258 
	C404.572144,127.282188 412.334320,120.175613 420.844666,112.957245 
	C443.810120,114.385254 465.079132,120.025726 485.798096,127.902832 
	C493.213226,130.721970 500.458862,133.986847 507.781860,137.048264 
	C499.618225,167.141647 503.421631,195.489700 522.610535,220.317093 
	C546.588623,251.341003 578.683899,262.722839 617.212830,255.463348 
	C620.989929,254.751678 622.676819,255.222519 624.239929,258.998413 
	C635.900513,287.165497 641.563538,316.538757 642.572693,346.900543 
	C642.615662,348.192535 642.333496,349.495300 642.151489,351.302032 
	C632.080017,351.302032 622.200256,351.302032 610.764526,351.302032 
	C620.162842,360.604309 628.509277,368.865448 636.971985,377.879913 
	C637.026306,380.293396 637.220093,382.010895 636.862549,383.604736 
	C633.466980,398.743317 630.446228,413.990234 626.321594,428.931030 
	C624.199463,436.617889 620.062317,443.748444 616.835754,451.130371 
	C592.183105,442.599365 567.787537,442.947784 544.205078,454.456604 
	C514.671631,468.869537 497.395081,492.706360 492.068268,525.130005 
	C489.673798,539.704773 491.303253,553.976196 495.786011,567.977783 
	C496.242340,569.403137 496.699585,570.828613 497.118805,572.265076 
	C497.207581,572.569153 497.131714,572.921387 497.131714,573.588684 
	C465.903320,587.204956 433.250824,594.192627 398.449554,594.070679 
	C398.449554,584.092407 398.449554,574.683167 398.449554,563.984375 
	C388.558319,573.346252 379.606079,581.819397 370.308228,590.411804 
	C335.641235,586.340759 303.516205,575.643250 273.792389,557.923462 
	C269.224579,555.200378 263.821808,552.897461 260.749847,548.904175 
	C257.241821,544.343994 262.078156,539.126465 263.143677,534.236938 
	C275.964111,475.405914 232.700378,420.805084 172.381790,420.152954 
	C168.253799,420.108307 166.336807,419.089966 165.232056,414.833771 
	C160.360687,396.066315 157.307541,377.083679 157.085480,357.674683 
	C157.070557,356.370361 157.083527,355.065704 157.083527,353.217163 
	C166.486053,353.217163 175.535706,353.217163 185.815994,353.217163 
	C175.760162,343.027802 166.592072,333.737976 157.244858,324.162842 
z"
        />

        <g className="text-move">
          {/* <Link to={"/services/what-it-costs?tab=Commercialization"}> */}
            <text
              x="224"
              y="148"
              textAnchor="middle"
              dominantBaseline="middle"
              fontWeight="500"
              fill="white"
              className="text-[1.5rem]"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Seller
            </text>
          {/* </Link> */}
        </g>

        <g class="text-move">
          {/* <Link to={"/services/what-it-costs?tab=Commercialization"}> */}
            <text
              x="172"
              y="515"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="white"
              className="text-[1.1rem]"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Commercialization
            </text>
          {/* </Link> */}
        </g>

        <g class="text-move">
          <Link to={"/services/what-it-costs?tab=Purchase"}>
            <text
              x="598"
              y="160"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="white"
              className="text-[1.5rem] hover:text-[2rem] transition-all"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Purchase
            </text>
          </Link>
        </g>
        <g class="text-move">
          <Link to={"/services/what-it-costs?tab=Renovation"}>
            <text
              x="586"
              y="543"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="white"
              className="text-[1.5rem] hover:text-[2rem] transition-all"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Renovation
            </text>
          </Link>
        </g>








		<g class="text-move">

		<text
              x="400"
              y="250"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="green"
              className="text-[2.5rem]"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Akiya2.0
            </text>

            <text
              x="400"
              y="320"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="green"
              className="text-[2.5rem]"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Consultation
            </text>

			<text
              x="400"
              y="390"
              text-anchor="middle"
              dominant-baseline="middle"
              font-weight="500"
              fill="green"
              className="text-[2.5rem]"
              style={{ fontFamily: "'Libre Franklin', sans-serif" }}
            >
              Service Ecosystem
            </text>
        </g>
      </svg>
    </div>
  );
};

export default OuServiceIcon;

{
  /* <div className="relative w-[100vh] h-[100vh] flex justify-center items-center">
<img src={OuService} className="w-[100%] h-[100%]" alt="Our Services" />
</div> */
}



{/* <div className="-translate-y-5">Akiya2.0</div>
<div className="-translate-y-5">Consultation</div>
<div className="-translate-y-5">Service Ecosystem</div> */}