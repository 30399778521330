const images = [];

function importAll(r) {
  return r.keys().map(r);
}

try {
  images.push(
    ...importAll(
      require.context(
        "../../Components/images/akiya-concierge",
        false,
        /\.(png|jpe?g|svg)$/
      )
    )
  );
  console.log(images[0]);
} catch (error) {
  console.log(error);
  console.error(error);
}

export const headingData = [
  {
    heading: "Akiya2.0 Concierge",
    info: "Elevate your akiya experience with Akiya2.0 Concierge, as we provide a central lobby location to help our traveler’s unique tastes. From our strategic location, we are able to provide customized support to provide you with help on all fronts of service.",
  },
  {
    heading: "Effortless Exploration",
    info: "To enhance the Akiya experience, we will customize transportation for each cluster so you can truly immerse yourself in the picturesque countryside. For example, in the Noto Peninsula cluster, a complimentary car comes with each Akiya reservation, and in Shimanami Kaido, each guest will be allotted a state-of-the-art 28-inch electric bicycle. We want to make the countryside more accessible for you, so you can enjoy an easy journey.",
  },
  {
    heading: "Drive Into Tranquility",
    info: "Forget the hassles of busy trains and booking taxis, and be able to fully customize your vacation so you can move how you want to move. Free to stop the car to walk on the beach or take a breath at a shrine on the side of the road, our 24/7 concierge ensures a smooth journey.",
  },
  {
    heading: "Local flavors, packaged",
    info: "We provide nothing but the best Japan has to offer. Indulge in our BBQ packages which featuring feature the freshest local meats and produce crafted in collaboration with regional chefs and farms. Let the flavors of the locale come to you, enhancing your stay with delightful and authentic culinary experiences.",
  },
  {
    heading: "Bespoke dining experience",
    info: "Feeling luxurious? Opt to elevate your stay with Akiya2.0 by calling for a personal chef for the night. Our chefs prepare delectable dishes right in the comfort of your akiya. We also provide an array of fermented foods such as homemade sourdough breads, wines/sake, and various spreads readily available for your gastronomic pleasure.",
  },
];

export const slidingBoxRight = {
  boxHeader: "Transportation",
  boxHeader2: "‍‍Book an Akiya, get a car/electric bicycle!",
  boxPara:
    <>Embark on boundless exploration with Akiya2.0's innovative offer—Each reservation includes a complimentary vehicle, turning your stay into an adventure. <br />To compliment this, our 24/7 concierge, strategically located in central clusters, facilitates seamless experiences, ensuring your journey is as memorable as your destination.</>,
  images: images.slice(0, 3), // Adjust indices as needed
};
export const slidingBoxLeft = {
  boxHeader: "Culinary delights",
  boxHeader2: "‍Good food available at your fingertips",
  boxPara:
    <>Savor the flavors of the region with: <br /><br />1] Akiya2.0's room service packages curated in collaboration with local chefs and farms. <br /> 2] Elevate your stay by opting for a personal chef for the night, creating a bespoke dining experience.</>, 
    // Embrace the culinary delights of fermented foods (SIG), including homemade sourdough breads, wines/sake, and an array of fermented spreads, readily available to tantalize your taste buds.",
  images: images.slice(3, 6), // Adjust indices as needed
};
export const imgContentData = [
  {
    title: "Akiya2.0 Concierge",
    subtitle: "Seamless service at your fingertips",
    content:
      "We provide 24-hour staffed standby concierge, available remotely but also physically located in central clusters (e.g., Wajima City for Noto). Consider it a hotel lobby for our decentralized akiya villas. Rest in the comfort of know that rain or shine, our concierge is ready to serve at a moments notice to provide the best service available for you.",
    image: images[6],
  },
  {
    title: "Smart appliances and technologies",
    subtitle: "Because rural does not have to mean uncomfortable",
    content:
      "At Akiya2.0, we supplement traditional living with our high-tech appliances and conveniences. Each house is equipped with cutting-edge technology, ensuring modern comforts without compromising on the essence of tradition.",
    image: images[7],
  },
];
