import React from "react";
import Properties_listing from "../homePage/HomePageComponents/Properties_listing";
function FirstSOV() {
  const heading1 = "Akiya2.0's own cluster revitalization projects";
  const heading1a =
    "Akiya2.0 is working on our own property clusters as well. With our own clusters, Akiya2.0 will prove how our community based decentralized hotel model works, and give you a glimpse of what’s possible.";
  const heading2 = "Akiya2.0 Cluster Destinations";
  const heading2a =
    "Here are our next Akiya Clusters. These are hidden gems that we want to share with you, and we continue to look for other secondary/tertiary regions that just need a bit of help, from us and you, to shine.";
  return (
    <>
      <h1 className="dark-header mt-[2rem]">{heading1}</h1>
      <h1 className="light-header mb-[2rem]">{heading1a}</h1>
      <Properties_listing />
      <h1 className="dark-header mt-[2rem]">{heading2}</h1>
      <h1 className="light-header mb-[2rem]">{heading2a}</h1>
    </>
  );
}

export default FirstSOV;
