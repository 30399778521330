import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../../Utils/CommonFunctions";
import { UserContext, useUpdateUser, useUser } from "../../Context/UserContext";

const useIndividualAkiyaHook = (id) => {
  const { akiya_id } = useParams();
  const [individualAkiya, setIndividualAkiya] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [municipality, setMunicipality] = useState(null);
  const [property_no, setProperty_no] = useState(null);
  const user = useUser();
  const updateUser = useUpdateUser();
  const { setShowModal } = useContext(UserContext);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (user) {
      const isPresent = user.favorite.find((f) => f === akiya_id);
      if (isPresent) {
        setIsFavorite(true);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchData(`https://app.akiya2.com/one_listing/${akiya_id}`).then((data) => {
      setIndividualAkiya(data);

      if (data) {
        let land;
        if (data.Area_of_Land) {
          land = data.Area_of_Land.replace("m", "").replace(" ", "");
          const isLastCharDigit = !isNaN(land.slice(-1));

          if (isLastCharDigit) {
            land += "m²";
          }
        }
        let house;
        if (data.Area_of_House) {
          house = data.Area_of_House.replace("m", "").replace(" ", "");
          const isLastCharDigitHouse = !isNaN(house.slice(-1));

          if (isLastCharDigitHouse) {
            house += "m²";
          }
        }
        let priceWithoutLastThreeDigits;
        let formattedPrice;
        if (data.Price) {
          priceWithoutLastThreeDigits = data.Price.toString().slice(0, -3);
          formattedPrice = priceWithoutLastThreeDigits.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
        }
        // Format the price with commas as thousand separators

        const japaneseYenSymbol = "\u00A5";
        console.log(data.Link_To_Page);
        console.log(data);
        const property_id = data.property_id;
        const parts = property_id.split("-");
        setMunicipality(
          parts[2].charAt(0).toUpperCase() + parts[2].toLowerCase().slice(1)
        );

        setProperty_no(parseInt(parts[3]) + 1);

        const propertyDetails = {
          Address: data.Address || "N/A",
          "Area of House": house || "N/A",
          "Area of Land": land || "N/A",
          "Contact Info": data.Contact_Info || "N/A",
          "Empty or Not": data.Empty_or_Not || "N/A",
          "No of Floors": data.No_of_Floors || "N/A",
          "Room Type": data.Room_Type || "N/A",
          "Serial Number": data.Serial_Number || "N/A",
          "Year of Construction": data.Year_of_Construction || "N/A",
          "Link to Official page": data.Link_to_Page || data.Link_To_Page || data.Link_to_Website || data.Link_To_Website || "N/A",
          Price: formattedPrice
            ? `${japaneseYenSymbol} ${formattedPrice}`
            : "Not found",
        };
        console.log("Data")
        console.log("Property Details:", propertyDetails);

        setTableData(propertyDetails);
      }
    });
  }, []);
  const handleToggleFavorite = (event) => {
    event.stopPropagation();
    console.log("1");
    if (!user) {
      setShowModal(true);
      console.log("2");
    } else if (isFavorite) {
      setIsFavorite(false);
      console.log("3");
      const favoriteData = user.favorite.filter((f) => f !== akiya_id);
      console.log(favoriteData);
      updateUser({ ...user, favorite: favoriteData });
    } else {
      setIsFavorite(true);
      console.log("4");
      const favoriteData = [...user.favorite, akiya_id];
      console.log(favoriteData);
      updateUser({ ...user, favorite: favoriteData });
    }
  };
  return {
    individualAkiya,
    setIndividualAkiya,
    tableData,
    municipality,
    property_no,
    handleToggleFavorite,
    isFavorite,
  };
};

export default useIndividualAkiyaHook;
