import React from "react";
import japanTravel from "../images/about/japantravel.png";
import o2ostays from "../images/about/o2ostays.png";
import suzu from "../images/about/suzu.png";
import wajima from "../images/about/wajima.png";
import noto from "../images/about/noto.png";
import kominka from "../images/about/kominka.png";
import kominka1 from "../images/about/kominka1.jpg";
import imabari from "../images/about/imabari.png";
import { Link } from "react-router-dom";
function ThirdSOV() {
  const head = "Introducing Our Partners.";
  const para = (
    <>
      We work closely alongside Japanese city/prefectural governments partner
      agencies in Japan.
      <br />
      As an outward facing Travel Club operator, we're open to partnerships with
      overseas travel businesses around the world.
    </>
  );

  return (
    <div>
      <h1 className="dark-header mb-4">{head}</h1>
      <p className="light-header mb-8">{para}</p>

      {/* <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/2 p-2 flex justify-center items-center">
          <Link className="w-[50%] h-auto" to="https://www.google.com">
            <img src={japanTravel} alt="Japan Travel" className="h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/2 p-2 flex justify-center items-center">
          <Link className="w-[50%] h-auto" to="https://www.google.com">

          <img src={o2ostays} alt="O2O Stays" className="h-auto" />
          </Link>
        </div>
      </div> */}


      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[70%] h-auto" to="https://en.japantravel.com/" target="_blank">
            <img src={japanTravel} alt="japantravel" className="h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[100%] h-auto" to="https://o2ostays.co/" target="_blank">
            <img src={o2ostays} alt="o2ostays" className="h-auto" />
          </Link>
        </div>
      </div>


      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[90%] h-auto" to="http://www.g-cpc.org/" target="_blank">
            <img src={kominka} alt="Kominka" className="h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/3 p-2 flex justify-center items-center">
          <Link className="w-[40%] h-auto" to="http://kominka.net/" target="_blank">
            <img src={kominka1} alt="Kominka1" className="h-auto" />
          </Link>
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center">
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.suzu.lg.jp.e.er.hp.transer.com/" target="_blank">
            <img src={suzu} alt="Suzu" className="w-full sm-[50%] h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.imabari.ehime.jp/" target="_blank">
            <img src={imabari} alt="Imabari" className="w-full h-auto" />
          </Link>
        </div>
        <div className="w-full sm:w-1/4 p-2">
          <Link className="w-[50%] h-auto" to="https://www.city.wajima.ishikawa.jp/" target="_blank">
            <img src={wajima} alt="Wajima" className="w-full h-auto" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThirdSOV;
