import FirstSOVFullImage from "../../images/Home/FirstSOVFullImage.jpeg"
import h2 from "../../images/Home/h2.jpeg"
import h3 from "../../images/Home/h3.jpeg"
import h4 from "../../images/Home/h4.jpeg"
import h5 from "../../images/Home/h5.jpeg"

export {FirstSOVFullImage}
export const noto = h2

export const sliderImages = [h3,h4,h5]

