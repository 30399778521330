import React from "react";
import Test2 from "./Test2";
import HeadingForAll from "../../HeadingForAll/HeadingForAll";
import OuServiceIcon from "./OurServicesSvg";
import AkiyaForm from "../../akiyaForm/AkiyaForm";
import HeadingContainer from "../../headingContainer/HeadingContainer";
import { howItWorksData } from "./data";
import { headingData, subHeadingdata } from "../../buyAnAkiya/BuyAnAkiyaHook";
function HowItWorks() {
  const { heading, paragraph, firstHeading, secondHeading, services } = howItWorksData;

  return (
    <>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="flex justify-center">
        <div className="max-w-[1440px]">
          <div className="text-justify mx-[30px] text-[22px] leading-[36px] my-[3rem]">
            <h3 className="font-[800] text-[32px] mb-3">{heading}</h3>
            <h5 className="text-[#949995] font-[300]">{paragraph}</h5>
          </div>
          <div className="relative flex justify-center items-center min-h-[500px] h-[320vw] w-full xs:h-[250vw] sm:h-[200vw] md:h-[10vw] max-h-[1200px]">
            <OuServiceIcon />
            {services.map((service, index) => (
              <div
                key={index}
                className={`absolute text-left text-[12px] sm:text-[16px] my-10 w-[42%] sm:w-[40%] mdlg:w-[20%] ${
                  service.position === "top-right" ? "top-[0%] right-[2rem]" : 
                  service.position === "bottom-right" ? "bottom-[0%] right-[2rem]" :
                  service.position === "bottom-left" ? "bottom-[0%] left-[2rem]" :
                  "top-[0%] left-[2rem]"
                }`}
              >
                <h3 className="font-[300] text-[14px] sm:text-[22px]">{service.title}</h3>
                <div className="font-bold">
                  {service.items.map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Test2 />
          <div className="h-[10vh] w-full"></div>
          <div className="w-full flex items-center justify-center">
            <div className="flex flex-col md:flex-row w-full max-w-[1440px] my-5 gap-12 mx-[3rem]">
              <div className="md:w-[50%] w-full p-2">
                <HeadingContainer data={headingData[1]} />
              </div>
              <div className="md:w-[50%] w-full">
                <AkiyaForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;