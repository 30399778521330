import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CardSlider from "../cardSlider/CardSlider";
import BlogCard from "../blogCard/BlogCard";
import formatDate from "../../FormatDate";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { fetchData } from "../../Utils/CommonFunctions";

const currentUrl = window.location.href;


function SingleBlog() {
  const [data, setData] = useState(null);

  const secondIntro = "Everyone knows there are over 8 million Akiya in Japan.";
  const aboutSecondIntro = "Here's the fuss-free way to actually own one.";
  const [latestBlogData, setLatestBlogData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`https://app.akiya2.com/blogs`);
        const data = await response.json();

        // Sort and slice the data
        const sortedData = data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        console.log(sortedData);
        setLatestBlogData(sortedData);
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    }

    fetchData(); // Call fetchData only once inside the useEffect
  }, []);
  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${currentUrl}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${currentUrl}`,
      icon: <FaXTwitter />,
    },
  ];
  const { blogId } = useParams();
  const singleBlog = latestBlogData[0];

  useEffect(() => {
    // This function will be called after the component is mounted
    // You can perform data fetching, subscriptions, or manually update the DOM here

    // For example, let's fetch some data from an API using Axios

    axios
      .get(`https://app.akiya2.com/blog/${blogId}`)
      .then((response) => {
        // Update the state with the fetched data
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // If you want to perform cleanup when the component unmounts,
    // you can return a function from useEffect
    return () => {
      // Cleanup logic here (e.g., unsubscribe from a subscription)
    };
  }, [blogId]); // Passing an empty dependency array means this effect will only run once after initial render

  // console.log(singleBlog);
  console.log("data");

  console.log(data);

  return (
    <div>
      <div className="w-[100%] mb-[12rem]">
        <div className="w-[100%] h-[30rem] bg-[#5ab963]"></div>

        <div className="absolute h-[90vh] w-[100vw] flex justify-center top-0 ">
          <div className="max-w-[70%] w-[fitcontent] h-[43rem] mt-[4.8rem]">
            <img
              className="max-h-[100%]"
              src={data && data.coverImage}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* Whole heading and sharing links */}
      <div className="px-[30px]">
        <div className="text-center text-[72px] font-[800] leadign-[78px]">
          {data && data.blog_heading}
        </div>

        <div className="flex justify-between mx-10">

          <div className="text-[#5ab963] text-[24px] font-[800]">
            {data && formatDate(data.createdAt)}
          </div>

          <div className="text-[#5ab963] text-[24px] font-[800]">
            {data && data.author}
          </div>

        </div>

        {/* Share links */}
        <div className="flex justify-end m-3 my-[3rem]">
          <p className="text-[18px] leading-[20px] font-[400]">Share on:</p>
          {links.map((x) => (
            <Link to={x.link} className="mx-2">
              <div className="flex justify-around items-center">
                <div>{x.icon}</div>
                <p className="text-[#999] px-1 text-[18px] leading-[20px]">
                  {x.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* blog content */}
      <div className="mx-[30px] text-[20px] leading-[35px] font-[400] mb-[5rem] max-h-[100%] overflow-auto">
        <div
          className=" max-h-[100%] box-border"
          dangerouslySetInnerHTML={{ __html: data && data.blog_content }}
        />
      </div>

      {/* Latest Blogs */}
      <div>
        <div className="text-center text-[#5ab963] text-[24px] font-[800] my-[2rem]">
          Latest Blogs
        </div>
        <div>
          {latestBlogData.length && (
            <CardSlider
              data={latestBlogData}
              Card={BlogCard}
              endValues={{
                xs: 1,
                md: 2,
                lg: 3,
              }}
            />
          )}
        </div>
      </div>

      <div className="flex mb-[5rem] mt-[10rem]">
        <div className="w-[50%]">
          <h1 className="dark-header m-0">{secondIntro}</h1>
          <h1 className="light-header m-0">{aboutSecondIntro}</h1>
        </div>
        <div className="flex justify-center items-center w-[50%]">
          <Link to="/services/how-it-works">
            <button className="py-[15px] px-[30px] bg-[#5ab963] rounded text-white text-[16px] font-[800]">
              Our Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SingleBlog;

// Object i
// author: "Rohit Naidu" blog_content:
// "<p>awdawfaw<img src=\"https://app.akiya2.com/public/blogs/1/images/public/blogs/1/images/image_1.jpg\" /><img src=\"https://app.akiya2.com/public/blogs/1/images/public/blogs/1/images/image_2
// blog_heading: "awfewdw"
// blog id: "6602d4b06bdc779c26a59806"
// coverImage: "https://app.akiya2.com/public/blogs/1/coverImage.jpg"
// createdAt: "2024-03-26T13:59:082"
// serial_number: 1
// tag: null
// • [[Prototypell: Object
