import React from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import FirstSOV from "./FirstSOV";
import SecondSOV from "./SecondSOV";
import ThirdSOV from "./ThirdSOV";
import FourthSOV from "./FourthSOV";
import { sliderImages } from "../community/useCommunityHook";
import TimeOutImages from "../sliderImagesContainer/SliderImagesContainer";

function AboutUs() {
  const firstHeading = "About Us";
  const secondHeading = "A team dedicated to Japan's rich heritage";

  return (
    <>
      <div className="bg-[#5ab963] w-full">
        {/* <div className='md:ml-[3rem] ml-[1.5rem] text-[#fff] pb-[1rem] w-[100%] max-w-[1440px]'>
        <h1 className="m-0 md:text-[24px] text-[15px] font-[800] pt-[0.5rem]">
            {firstHeading}
          </h1>
          <h1 className="md:text-[72px] text-[32px] font-[800] md:leading-[78px] leading-[35px] pt-[0.5rem] pb-[10.5rem]">
            {secondHeading}
          </h1>
        </div> */}
        <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      </div>

      <div className="flex justify-center">
        <div className="max-w-[1440px]">
          {/* <div className="lg:h-[90vh] h-[fit-content] w-[100%] translate-y-[-15%] flex justify-center">
          <div className="w-[80%]">
            <TimeOutImages images={sliderImages} />
          </div>
          </div> */}
          <div className="mt-10"></div>
          <FirstSOV />
          <SecondSOV />
          <ThirdSOV />

          <FourthSOV />
        </div>
      </div>
    </>
  );
}

export default AboutUs;
