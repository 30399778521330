import op1 from "../images/ourProjects/op1.jpeg"
import op2 from "../images/ourProjects/op2.jpeg"
import op3 from "../images/ourProjects/op3.jpeg"
import op4 from "../images/ourProjects/op4.jpeg"
import op5 from "../images/ourProjects/op5.jpeg"
import op6 from "../images/ourProjects/op6.jpeg"
import op7 from "../images/ourProjects/op7.jpeg"
import op8 from "../images/ourProjects/op8.jpeg"
import op9 from "../images/ourProjects/op9.jpeg"
import op10 from "../images/ourProjects/op10.jpeg"
import op11 from "../images/ourProjects/op11.jpeg"
import op12 from "../images/ourProjects/op12.jpeg"
import op13 from "../images/ourProjects/op13.jpeg"
import op14 from "../images/ourProjects/op14.jpeg"



export const shinanamiKaidoFamousPlaces = [
  {
    placeName: "Kosan-ji Temple",
    mainImageUrl: op11,
    aboutThePlace:
      "An elaborate Buddhist temple renowned for its intricate architecture, stunning gardens, and a museum housing a vast collection of valuable artifacts.",
  },
  {
    placeName: "Tatara Shimanami Park",
    mainImageUrl: op12,
    aboutThePlace:
      "A beautiful park located along the cycling route, providing panoramic views of the Seto Inland Sea and the iconic Kurushima-Kaikyō Bridge.",
  },
  {
    placeName: "Oyamazumi Shrine",
    mainImageUrl: op13,
    aboutThePlace:
      "A historic Shinto shrine on Omishima Island, known for its collection of ancient swords and armor. The serene surroundings add to the spiritual atmosphere.",
  },
  {
    placeName: "Hirayama Ikuo Silk Road Museum",
    mainImageUrl: op14,
    aboutThePlace:
      "Dedicated to the works of renowned artist Hirayama Ikuo, this museum showcases his Silk Road-themed paintings and artifacts, providing a cultural stop on the cycling journey.",
  },
];

export const notoFamousPlaces = [
  {
    placeName: "Keta Taisha Shrine",
    mainImageUrl: op4,
    aboutThePlace: (
      <>
        Dating back to over 2000 years ago, Keta Taisha is the most significant
        shrine on Noto peninsula.
        <br />
        <br />
        Enshrined is Onamuchi, a deity of love.
      </>
    ),
  },
  {
    placeName: "Shiroyone Senmaida Rice Terrace",
    mainImageUrl: op5,
    aboutThePlace:
      "A classic Japanese rural scenery ... but uniquely, overlooking the great Sea of Japan.",
  },
  {
    placeName: "Okunoto Salt Farm",
    mainImageUrl: op6,
    aboutThePlace:
      "Catch this traditional method of salt making as it has been performed for centuries.",
  },
  {
    placeName: "Wakura Onsen",
    mainImageUrl: op7,
    aboutThePlace:
      "In need for a dip? Visit the local seaside onsen resort which is also famed for a cluster of seafood restaurants.",
  },
];


export const noto = {
    imagesLinks : [op1, op2, op3],
    data: {
        title:"Noto Peninsula",
        about:"A hidden gem of the Sea of Japan",
        largeAbout: "The Noto Peninsula (能登半島) in Ishikawa Prefecture extends about 100 kilometers into the Sea of Japan. The peninsula is known for its coastal scenery, particularly along the Okunoto Coast and the Kongo Coast, as well as for its rural atmosphere. Off the beaten path for even experience, it provides a quality seaside experience for the entire family."
    }
}

export const shimanamiKaido = {
    imagesLinks : [op8, op9, op10],
    data: {
        title:"Shimanami Kaido",
        about:"‍Cycling Paradise of the Seto Inland Sea",
        largeAbout: "The Shimanami Kaido unfolds as a cycling paradise connecting the islands of the Seto Inland Sea. Celebrated for its scenic landscapes and well-crafted cycling routes, it provides a harmonious blend of nature and infrastructure. Whether an avid enthusiast or a casual rider, the Shimanami Kaido offers an unforgettable journey through the breathtaking beauty of the Seto Inland Sea."
    }
}