import React from "react";
import "./bottomNavbar.css";
import MainLogo from "../images/bottomNavbar/BottomNavbarLogo.png";
import { Link } from "react-router-dom";
import { FaXTwitter, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import useWindowDimensions from "../useWindowDimensions";

const navItems = [
  {
    name: "Our Services",
    link: "/services/how-it-works",
    subItems: [
      { name: "Akiya Search", link: "akiya-search" },
      { name: "How it Works", link: "/services/how-it-works" },
      { name: "What it Costs", link: "/services/what-it-costs" }
    ]
  },
  {
    name: "Our Projects",
    link: "our-projects",
    subItems: [
      { name: "Akiya2.0 Concierge", link: "/akiya-concierge" }
    ]
  },
  {
    name: "Invest in Akiya",
    link: "invest-in-akiya",
    subItems: []
  },
  {
    name: "Community",
    link: "community",
    subItems: [
      { name: "News", link: "news" },
      { name: "Blogs", link: "blogs" }
    ]
  },
  {
    name: "About Us",
    link: "about-us",
    subItems: [
      { name: "Akiya2.0 Team", link: "/about-us/#founders", isHashLink: true },
      { name: "Internship", link: "/internship/#interns", isHashLink: true }
    ]
  },

  {
    name: "",
      subItems: [
        { name: "Term/Privacy", link: "terms", special: true },
        { name: "Contact Us", link: "/about-us/#contact", isHashLink: true }
      ]
  }
];

const socialLinks = [
  // { name: "Twitter", icon: FaXTwitter, link: "https://twitter.com/AkiyaTwo" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/akiya2.0/" },
  { name: "LinkedIn", icon: FaLinkedin, link: "https://www.linkedin.com/company/akiya2-0-kk/about/" },
  // { name: "TikTok", icon: FaTiktok, link: "https://www.tiktok.com" },
  { name: "YouTube", icon: FaYoutube, link: "https://www.youtube.com/channel/UCU9UXi25eRRuBUT0k9GeDzg" }
];

function BottomNavbar() {
  const { width } = useWindowDimensions();

  const renderNavItem = (item, isColumn = false) => (
    <div key={item.name} className={`px-3 ${isColumn ? "flex-col" : ""}`}>
      <div className="flex-col-reverse text-[#fff]">
        <div className="text-[12px] font-[800] mb-[8px]">
          <Link to={item.link}>{item.name}</Link>
        </div>
        {item.subItems.map((subItem) => (
          <div
            key={subItem.name}
            className={`text-[12px] font-[400] leading-[24px] ${subItem.special ? "special-link" : ""}`}
          >
            {subItem.isHashLink ? (
              <HashLink smooth to={subItem.link}>
                {subItem.name}
              </HashLink>
            ) : (
              <Link to={subItem.link}>{subItem.name}</Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
  

  // const renderSocialLinks = () => (
  //   <div className={`grid gap-2 ${width >= 1024 ? "grid-cols-2" : "grid-cols-4"} text-[12px] font-[800] mb-[8px]`}>
  //     {socialLinks.map((social) => (
  //       <Link key={social.name} to={social.link} target="_blank">
  //         <social.icon className="text-[1rem] m-2" />
  //       </Link>
  //     ))}
  //   </div>
  // );

  const renderSocialLinks = () => (
    <div className="flex text-[12px] font-[800] mb-[8px]">
      {socialLinks.map((social) => (
        <Link key={social.name} to={social.link} target="_blank">
          <social.icon className="text-[1rem] m-2" />
        </Link>
      ))}
    </div>
  );

  const renderLogo = (logoWidth = "w-[180px]") => (
    <div className={`logo h-[100%] ${logoWidth} flex items-center`}>
      <Link to="/">
        <img src={MainLogo} alt="Main Logo" />
      </Link>
    </div>
  );

  if (width >= 1024) {
    return (
      <div className="bottom-navigation-container w-[100%] bg-[#0a0e1a] min-h-[100%] pb-[150px] px-[25px] pt-[90px] bottom-0 z-50 flex justify-center">
        <div className="flex justify-center gap-1 w-[100%] max-w-[1440px]">
          <div className="w-[18%]">{renderLogo()}</div>
          {navItems.map(renderNavItem)}
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {renderSocialLinks()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (width >= 768 && width < 1024) {
    return (
      <div className="bottom-navigation-container w-[100%] bg-[#0a0e1a] min-h-[100%] pb-[150px] px-[25px] pt-[90px] bottom-0 z-50 flex justify-center">
        <div className="flex justify-center gap-2 w-[100%] max-w-[1440px]">
          <div className="w-[25%]">{renderLogo("w-full")}</div>
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {navItems.slice(0, 3).map((item) => renderNavItem(item, true))}
            </div>
          </div>
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {navItems.slice(3, 5).map((item) => renderNavItem(item, true))}
            </div>
          </div>
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {renderNavItem(navItems[5], true)}
              {renderSocialLinks()}
            </div>
          </div>
        </div>
      </div>
    );
  }
  

  if (width < 768 && width > 540) {
    return (
      <div className="bottom-navigation-container w-[100%] bg-[#0a0e1a] min-h-[100%] pb-[100px] px-[25px] pt-[60px] bottom-0 z-50 flex justify-center">
        <div className="flex justify-center gap-2 w-[100%] max-w-[1440px]">
          <div className="w-[30%]">{renderLogo("w-full")}</div>
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {navItems.slice(0, 3).map((item) => renderNavItem(item, true))}
            </div>
          </div>
          <div className="px-3">
            <div className="flex-col-reverse text-[#fff]">
              {navItems.slice(3).map((item) => renderNavItem(item, true))}
              {renderSocialLinks()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bottom-navigation-container w-[100%] bg-[#0a0e1a] min-h-[100%] pb-[70px] px-[25px] pt-[40px] bottom-0 z-50 flex justify-center">
      <div className="flex justify-center gap-2 w-[100%] max-w-[1440px]">
        <div className="px-3 text-center">
          <div className="flex-col-reverse text-[#fff]">
            <div className="w-full flex justify-center">
              {renderLogo("w-[40%]")}
            </div>
            {navItems.map((item) => renderNavItem(item, true))}
            <div className="flex justify-center">{renderSocialLinks()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomNavbar;
